<template>
  <div class="panel panel-default panel-fill" style="position: relative">
    <div class="panel-heading">
      <h3 class="panel-title">{{ trans("region.region") }}</h3>
    </div>
    <div class="panel-body">
      <select v-model="center.region_id" class="form-control">
        <option :value="null">
          {{ trans("region.chooseDermicusRegion") }}
        </option>
        <option v-for="region in regions" :key="region.id" :value="region.id">
          {{ region.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Select2 from "../../Utils/select2.vue";
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";

export default {
  components: {
    Select2,
  },
  props: {
    center: {},
  },
  setup() {
    const regions = ref([]);

    async function loadRegions() {
      try {
        const response = await practitionerHttpService.get(
          "/api/internal/admin/all-regions",
        );
        regions.value = response.data;
      } catch (error) {
        console.error("Error loading regions:", error);
      }
    }

    onMounted(loadRegions);

    return {
      regions,
    };
  },
};
</script>
