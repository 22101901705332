<template>
  <div class="card">
    <div class="card-header">Excluded web authentication methods</div>
    <div class="card-body">
      <div class="card-text">
        <div v-for="method in webAuthMethods" :key="method" class="form-check">
          <input
            class="form-check-input"
            :checked="selectedWebMethods.includes(method)"
            type="checkbox"
            @change="toggleWebMethod(method)"
          />
          <label class="form-check-label" :for="method">{{
            trans(`general.authentication-method.${method}`)
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";

export default {
  props: {
    features: {},
    featureId: {},
    region: {},
  },
  data() {
    return {
      webAuthMethods: [],
      selectedWebMethods: [],
    };
  },
  created() {
    this.fetchAuthMethods();
    this.selectedWebMethods = [...(this.features[this.featureId].config || [])];
  },
  methods: {
    fetchAuthMethods() {
      practitionerHttpService
        .get("/api/internal/admin/auth-methods")
        .then((response) => {
          this.webAuthMethods = response.data.auth_methods;
        });
    },
    toggleWebMethod(method) {
      if (this.selectedWebMethods.includes(method)) {
        this.selectedWebMethods = this.selectedWebMethods.filter(
          (m) => m !== method,
        );
      } else {
        this.features[this.featureId].active = true;
        this.selectedWebMethods.push(method);
      }
      this.features[this.featureId].config = [...this.selectedWebMethods];
    },
  },
};
</script>
