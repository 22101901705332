<template>
  <div class="col-lg-12">
    <CenterHeader :selected-menu-item="1" />

    <div class="tab-content">
      <div id="settings" class="tab-pane active">
        <CenterReportOptionsForm
          v-if="dataCenter.id"
          :data-center-id="dataCenter.id"
        />
        <div class="panel panel-default panel-fill">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ `${lang.get("general.edit")} ${dataCenter.name}` }}
            </h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="m-b-20 col-md-12">
                <div class="tw-flex tw-gap-2 tw-items-center tw-mb-2">
                  <label class="tw-mb-0" for="center-logo">{{
                    lang.get("center.logo")
                  }}</label>
                  <VDropdown>
                    <template #popper>
                      {{ lang.get("center.logoInfo") }}
                    </template>
                    <i class="fal fa-info-circle" />
                  </VDropdown>
                </div>
                <AvatarLogo
                  :data-resource="dataAvatarResource"
                  @avatar-upload="$emit('avatarUpload', $event)"
                  @avatar-delete="$emit('avatarDelete', $event)"
                />
              </div>

              <div class="m-b-20 col-md-6">
                <label for="FullName">{{ lang.get("center.name") }}</label>
                <input
                  id="center_name"
                  v-model="dataCenter.name"
                  type="text"
                  name="name"
                  class="form-control"
                  disabled
                />
              </div>

              <div class="m-b-20 col-md-6">
                <label for="FullName">{{ lang.get("center.hsaId") }}</label>
                <input
                  id="hsa-id"
                  v-model="dataCenter.hsa_id"
                  type="text"
                  name="hsaid"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-12">
                <h5>
                  {{ lang.get("center.headGroupLabelContactInformation") }}
                </h5>
              </div>

              <div class="m-b-20 col-md-12">
                <label for="contact_person">{{
                  lang.get("center.contactPerson")
                }}</label>
                <input
                  id="contact_person"
                  v-model="dataCenter.contact_person"
                  type="text"
                  name="contact_person"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-12">
                <label for="FullName">{{ lang.get("center.email") }}</label>
                <input
                  id="email"
                  v-model="dataCenter.email"
                  type="email"
                  name="email"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-12">
                <label for="FullName">{{ lang.get("center.contactNo") }}</label>
                <input
                  id="contact_no"
                  v-model="dataCenter.contact_no"
                  type="text"
                  name="address"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-12">
                <label for="FullName">{{ lang.get("center.fax") }}</label>
                <input
                  id="fax"
                  v-model="dataCenter.fax"
                  type="text"
                  name="fax"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-12">
                <label for="FullName">{{ lang.get("center.address") }}</label>
                <input
                  id="address"
                  v-model="dataCenter.address"
                  type="text"
                  name="address"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-6">
                <label for="FullName">{{ lang.get("center.city") }}</label>
                <input
                  id="city"
                  v-model="dataCenter.city"
                  type="text"
                  name="address"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-6">
                <label for="FullName">{{ lang.get("center.postcode") }}</label>
                <input
                  id="postcode"
                  v-model="dataCenter.postcode"
                  type="text"
                  name="postcode"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-6">
                <label for="FullName">{{ lang.get("center.country") }}</label>
                <country-select
                  v-model="dataCenter.country"
                  :country="dataCenter.country"
                  :placeholder="trans('center.countryPlaceholder')"
                  top-country="SE"
                  class="form-control"
                />
              </div>

              <div class="m-b-20 col-md-6">
                <label for="FullName">{{ lang.get("center.province") }}</label>
                <region-select
                  v-model="dataCenter.province"
                  :country="dataCenter.country"
                  :placeholder="trans('center.provincePlaceholder')"
                  :region="dataCenter.province"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <PlatformAttributeEditor
          :platform="dataCenter.platform"
          :platform-attributes="dataCenter.platform_attributes"
          :platform-attribute-values="dataCenter.platform_attribute_values"
        />
        <div class="text-right">
          <button
            class="btn btn-primary mb-4"
            type="button"
            @click="saveData()"
          >
            {{ lang.get("general.formSaveLabel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import AvatarLogo from "../AvatarLogo.vue";
import CenterHeader from "./CenterHeader.vue";
import "vue3-country-region-select";
import PlatformAttributeEditor from "../PlatformAttributes/PlatformAttributesEditor.vue";
import { lang } from "../../i18n";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { toRefs } from "vue";
import { AvatarResource } from "../../store/core/avatar";
import CenterReportOptionsForm from "../CenterReportOptionsForm.vue";
import { notify } from "@kyvg/vue3-notification";

const props = defineProps<{
  dataCenter: { id: number } & Record<string, unknown>;
  dataSaveSettings: (centerId: number, data: unknown) => Promise<void>;
  dataAvatarResource: AvatarResource;
}>();
const { dataCenter, dataSaveSettings } = toRefs(props);

defineEmits<{
  (event: "avatarUpload", payload: { identifier: string; data: unknown }): void;
  (event: "avatarDelete", payload: { identifier: string }): void;
}>();

function saveData() {
  dataSaveSettings
    .value(dataCenter.value.id, dataCenter.value)
    .then(() => {
      notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    })
    .catch(userErrorMessage);
}
</script>
