<template>
  <div class="card" style="width: 100%">
    <div class="card-header">{{ label }}</div>
    <div class="card-body">
      <div class="card-text col-md-12">
        <DerToggleWithLabel
          :disabled="!center.id || !center.active"
          :model-value="features[featureId].active"
          :label="label"
          @update:model-value="toggleFeature"
        />
      </div>
      <p />
      <div v-if="!center.id" class="alert alert-warning my-4">
        {{ centerNeedsToBeCreated }}
      </div>
      <div v-if="features[featureId].active">
        <Table :headings="tableHeadings">
          <RequestStudyConsentRow
            v-for="study in studies"
            :key="study.id"
            :study="study"
            :center-id="center.id"
          />
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";
import Table from "../../Layout/Table.vue";
import RequestStudyConsentRow from "./RequestStudyConsentRow.vue";
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";
import { lang } from "../../../i18n";

export default {
  components: {
    DerToggleWithLabel,
    Table,
    RequestStudyConsentRow,
  },
  props: {
    center: Object,
    features: Object,
    featureId: Number,
    label: String,
  },
  data() {
    return {
      studies: [],
      connectedStudies: new Set(),
    };
  },
  computed: {
    tableHeadings() {
      return [
        { title: lang.get("model.studyConsent.status.label") },
        { title: lang.get("model.studyConsent.study.label") },
        { title: "" },
      ];
    },
    centerNeedsToBeCreated() {
      return lang.get("admin.features.centerNeedsToBeCreated");
    },
  },
  async created() {
    await this.loadStudies();
  },
  methods: {
    async loadStudies() {
      const [availableResponse, connectedResponse] = await Promise.all([
        practitionerHttpService.get("/api/internal/consent-studies/"),
        practitionerHttpService.get(
          `/api/internal/centers/${this.center.id}/consent-studies`,
        ),
      ]);

      this.connectedStudies = new Set(connectedResponse.data.map((s) => s.id));
      this.studies = availableResponse.data.data.map((study) => ({
        ...study,
        isConnected: this.connectedStudies.has(study.id),
      }));
    },
    toggleFeature(value) {
      if (
        !value &&
        !confirm(lang.get("admin.disableRequestStudyConsentConfirmation"))
      ) {
        return;
      }

      if (value) {
        this.loadStudies();
      }

      this.features[this.featureId].active = value;
    },
  },
};
</script>
