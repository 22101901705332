import { operator } from "../../../lib/field-conditions";
import { fieldMap } from "./fieldMap";

/**
 * Maps generic product config to list of component configs for rendering.
 */
export const mapComponents = (
  product,
  userPermissions,
  examination,
  viewOption,
) => {
  let enabledGroups = product.field_groups.filter((group) =>
    fieldGroupIsAllowed(group, userPermissions, examination),
  );

  if (viewOption) {
    enabledGroups = enabledGroups.filter((group) => {
      if (group.view_options !== null) {
        const options = parse(JSON.parse(group.view_options));

        return viewOption in options;
      }
      return false;
    });
  }

  const components = [];
  enabledGroups.forEach((fieldGroup) => {
    if (fieldGroup.frontend_type in fieldMap) {
      const options = parse(JSON.parse(fieldGroup.view_options));

      const fieldType =
        "frontend_type" in options[viewOption]
          ? options[viewOption].frontend_type
          : fieldGroup.frontend_type;
      components.push({
        element: fieldMap[fieldType],
        elementName: fieldType,
        props: {
          fields: filterConditionalFields(fieldGroup, userPermissions),
          examination: examination,
          sortOrder: viewOption
            ? options[viewOption].sort_order
            : fieldGroup.sort_order,
          product: product,
          options:
            viewOption && viewOption in options
              ? options[viewOption]
              : undefined,
          viewOption,
          fieldGroupName: fieldGroup.frontend_type,
        },
      });
    }
  });

  return components.sort(sortComponents);
};

function parse(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const sortComponents = (a, b) => {
  if (a.props.sortOrder < b.props.sortOrder) return -1;
  if (a.props.sortOrder > b.props.sortOrder) return 1;
  return 0;
};

const filterConditionalFields = (fieldGroup, userPermissions) => {
  const { frontend_type, frontend_fields, write_permission, read_permission } =
    fieldGroup;
  const fields = frontend_fields;
  fields.forEach((field) => {
    const write = canWrite(write_permission, userPermissions);
    const read = canRead(read_permission, userPermissions);
    field.disabled = !write;
    field.visible = read;
    if (
      frontend_type === "FIELD_GROUP_TYPE_FORM" ||
      frontend_type === "FIELD_GROUP_STUDY_TYPE_FORM"
    ) {
      field.visible = read;
      field.disabled = !write;
    } else {
      field.visible = true;
    }
  });
  return fields;
};

const fieldGroupIsAllowed = (fieldGroup, userPermissions, examination) => {
  let isAllowed = true;
  if (
    fieldGroup.read_permission &&
    !canRead(fieldGroup.read_permission, userPermissions)
  ) {
    isAllowed = false;
  }
  if (!fieldGroupIsVisible(fieldGroup, examination)) isAllowed = false;
  return isAllowed;
};

const fieldGroupIsVisible = (fieldGroup, examination) => {
  let isVisible = true;
  if (fieldGroup.view_options !== null) {
    const options = parse(JSON.parse(fieldGroup.view_options));
    if ("field_conditions" in options && options.field_conditions.length > 0) {
      const { field_conditions } = options;
      field_conditions.forEach((condition) => {
        isVisible = operator[condition.operator](
          examination[condition.key],
          condition.value,
        );
      });
    }
  }
  return isVisible;
};

export const canRead = (readPermission, userPermissions) => {
  if (readPermission === null) return true;
  return userPermissions.includes(readPermission.name);
};

export const canWrite = (writePermission, userPermissions) => {
  if (writePermission === null) return true;
  return userPermissions.includes(writePermission.name);
};
