<template>
  <div>
    <div v-if="userHasNoProducts" class="overlay">
      <div class="overlay-content">{{ userHasNoProductsMessage }}</div>
    </div>
    <div>
      <div
        v-if="isExaminationsFiltersLoaded"
        tabindex="-1"
        style="outline: none"
        @keyup.enter="getResults(1)"
      >
        <div class="filter-search-bar">
          <div class="row px-3">
            <v-select
              v-if="$can('filter_consultations')"
              class="col-md-3 my-3"
              name="examinationsFilters"
              :options="searchOptions.examinationsFilters"
              :reduce="(filter) => filter.value"
              :model-value="searchFields.mainFilter"
              label="text"
              :placeholder="
                trans('evaluation.selectExaminationFilterPlaceholder')
              "
              @update:model-value="filter($event)"
            ></v-select>

            <v-select
              v-if="$can('filter_consultations')"
              class="col-md-3 my-3"
              multiple
              name="extraFilters"
              :options="searchOptions.extraFilters"
              :model-value="selectedExtraCaseFiltersList"
              label="text"
              :placeholder="trans('evaluation.selectCaseFilterPlaceholder')"
              @update:model-value="selectExtraFilters($event)"
            ></v-select>

            <form class="col my-3 input-group" @submit.prevent="getResults(1)">
              <input
                id="search-field"
                v-model="searchFields.query"
                type="text"
                class="form-control"
                :placeholder="trans('general.searchCaseAndPatient')"
                :title="trans('general.search')"
              />

              <span class="input-group-btn pl-4">
                <button
                  v-if="isSearching"
                  class="btn btn-danger mr-2"
                  type="button"
                  @click.stop="clearSearch"
                >
                  <span>{{ trans("general.clearSearch") }}</span>
                  <i class="fal fa-times"></i>
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  @click.stop="getResults(1)"
                >
                  <span>{{ trans("general.search") }}</span>
                  <i class="far fa-search"></i>
                </button>
              </span>
            </form>
          </div>
        </div>

        <div v-if="isAdvancedSearchOpen" class="advanced-search">
          <div class="row">
            <div v-if="products.length > 1" class="col-md-4">
              <label>{{ trans("evaluation.tableColumnProduct") }}</label>
              <v-select
                name="products"
                :options="products"
                :model-value="searchFields.products"
                label="display_name"
                :reduce="(product) => product.id"
                :placeholder="
                  trans('examination.externalResourceSelectPlaceholder')
                "
                @update:model-value="selectProduct($event)"
              ></v-select>
            </div>
          </div>

          <search-option-basic
            :search-fields="searchFields"
            :search-options="searchOptions"
          ></search-option-basic>

          <search-option-mole
            v-if="moleDiagnosisFieldsVisible"
            :search-fields="searchFields"
            :search-options="searchOptions"
          ></search-option-mole>

          <search-option-mole-uk
            v-else-if="
              searchFields.products == PRODUCT_ID.MOLE_UK ||
              userSingleProductId == PRODUCT_ID.MOLE_UK
            "
            :search-fields="searchFields"
            :search-options="searchOptions"
          ></search-option-mole-uk>

          <div class="row">
            <div class="col-md-4">
              <span class="float-left pb-2">
                {{ trans("evaluation.numberOfSearchResult") }}
                {{ paginate.total }}
              </span>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="filter-links">
          <span class="float-right pb-2">
            <a class="advanced-btn" @click="toggleAdvancedSearch">
              {{ trans("evaluation.advancedShow") }}
              <i
                class="far fa-angle-down"
                :class="[
                  isAdvancedSearchOpen ? 'fa-angle-up' : 'fa-angle-down',
                  'far',
                ]"
              ></i>
            </a>
          </span>
        </div>
      </div>

      <div id="evaluations">
        <clip-loader v-show="loading"></clip-loader>

        <div class="table-responsive">
          <table class="table table-hover table-actions-bar cases-list">
            <thead class="sortable-table-header">
              <tr>
                <th
                  v-if="$store.state.user.has_ai_prioritization"
                  class="consultations-list-icon"
                ></th>
                <th
                  v-if="$can('prioritise_cases')"
                  class="consultations-list-icon"
                ></th>
                <th
                  v-if="$can('prioritise_cases')"
                  class="consultations-list-icon"
                ></th>
                <th
                  v-if="$can('prioritise_cases')"
                  class="consultations-list-icon"
                ></th>
                <th>{{ trans("evaluation.tableColumnCaseNo") }}</th>
                <th
                  class="sort-by"
                  :class="sortBySelection === 'status' ? 'table-active' : ''"
                  @click="sortBy('status', $event)"
                >
                  {{ trans("evaluation.tableColumnStatus") }}
                  <i class="fas fa-caret-down"></i>
                </th>
                <th v-if="$store.state.user.has_svf_product">
                  {{ trans("examination.svfLabel") }}
                </th>
                <th>{{ trans("evaluation.tableColumnProduct") }}</th>
                <th v-if="!$store.state.user.hidePatientFields">
                  {{ trans("evaluation.tableColumnPersonalNo") }}
                </th>
                <th
                  class="sort-by created-at"
                  :class="sortBySelection === 'createdAt' ? 'table-active' : ''"
                  @click="sortBy('createdAt', $event)"
                >
                  {{ trans("evaluation.tableColumnCreatedAt") }}
                  <i class="createdAtSortIcon fas fa-caret-down"></i>
                </th>
                <th>{{ trans("evaluation.tableColumnCreatedBy") }}</th>
                <th
                  class="sort-by"
                  :class="sortBySelection === 'centerId' ? 'table-active' : ''"
                  @click="sortBy('centerId', $event)"
                >
                  {{ trans("evaluation.tableColumnCenter") }}
                  <i class="fas fa-caret-down"></i>
                </th>
                <th>{{ trans("evaluation.tableColumnConsultReply") }}</th>
                <th v-if="$can('consult')">
                  {{ trans("evaluation.tableColumnInternalConsultReply") }}
                </th>
                <th v-if="$can('consult')">
                  {{ trans("evaluation.tableColumnMessages") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(examination, index) in examinations"
                :key="index"
                :class="{
                  opened: examination.hasRead,
                  last: isLastOpend(examination),
                }"
                @click="openExamination(examination)"
              >
                <td
                  v-if="$store.state.user.has_ai_prioritization"
                  class="consultations-list-icon"
                >
                  <!-- implement real examination prop when it exist -->
                  <ai-priority-icon
                    v-if="examination.aiPriority"
                    :priority="examination.aiPriority"
                  ></ai-priority-icon>
                </td>
                <td
                  v-if="$can('prioritise_cases')"
                  class="consultations-list-icon"
                >
                  <bookmark-icon v-if="examination.isBookmarked" />
                </td>
                <td
                  v-if="$can('prioritise_cases')"
                  class="consultations-list-icon"
                >
                  <flag-icon v-if="examination.isFlagged" />
                </td>
                <td
                  v-if="$can('prioritise_cases')"
                  class="consultations-list-icon"
                >
                  <priority-icon v-if="examination.isPrioritized" />
                </td>
                <td>{{ examination.caseNo }}</td>
                <td>
                  <case-status
                    :examination="examination"
                    :hide-label="true"
                  ></case-status>
                </td>
                <td v-if="$store.state.user.has_svf_product">
                  {{ examination.isSvf ?? "–" }}
                </td>
                <td>{{ examination.type }}</td>
                <td v-if="!$store.state.user.hidePatientFields">
                  {{ examination.patient.ssn }}
                </td>
                <td v-if="$store.state.dermicusPlatform === 'stockholm'">
                  {{
                    examination.photographedAt
                      ? examination.photographedAt
                      : "–"
                  }}
                </td>
                <td v-else>{{ examination.createdAt }}</td>
                <td :title="examination.createdUserFullName">
                  {{ examination.createdUser }}
                </td>
                <td>{{ examination.center }}</td>
                <td>{{ examination.lastComment }}</td>
                <td v-if="$can('consult')">
                  <span
                    v-for="(
                      consultCommentUsers, index
                    ) in examination.consultCommentUsers"
                    :key="index"
                    v-dompurify-html:[DOMPURIFY_CONFIG_NAME.consultCommentUsers]="
                      consultCommentUsers
                    "
                  ></span>
                </td>
                <td v-if="$can('consult')">
                  <consensus-messages-count
                    :all-messages="examination.totalMessages"
                    :unread-messages="examination.notifications"
                  ></consensus-messages-count>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row-fluid float-right">
          <DerPaginate
            v-model="paginate.current_page"
            :page-count="paginate.last_page"
            @change="getResults"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchOptionMole from "./SearchOptionMole.vue";
import SearchOptionMoleUk from "./SearchOptionMoleUK.vue";
import SearchOptionBasic from "./SearchOptionBasic.vue";
import ConsensusMessagesCount from "../Utils/ConsensusMessagesCount.vue";
import AiPriorityIcon from "../Utils/AiFeatures/AiPriorityIcon.vue";
import PriorityIcon from "../Utils/ActionIcons/PriorityIcon.vue";
import FlagIcon from "../Utils/ActionIcons/FlagIcon.vue";
import BookmarkIcon from "../Utils/ActionIcons/BookmarkIcon.vue";
import TestIcon from "../Utils/ActionIcons/TestIcon.vue";
import CaseStatus from "../Examinations/Examination/ExaminationComponents/components/CaseStatus.vue";

import {
  getProductType,
  PRODUCT_ID,
  productHasPermission,
} from "../../helpers/product";
import { PRODUCT_PERMISSIONS, PRODUCTS } from "../../helpers/definitions";
import DerPaginate from "../../design-system/DerPaginate.vue";
import { DOMPURIFY_CONFIG_NAME } from "../../dompurify";
import { setHistoryState } from "../../lib/history-state";
import { queryStringBuilder } from "../../helpers/misc";

export default {
  components: {
    SearchOptionMole,
    SearchOptionMoleUk,
    SearchOptionBasic,
    ConsensusMessagesCount,
    AiPriorityIcon,
    PriorityIcon,
    BookmarkIcon,
    TestIcon,
    FlagIcon,
    CaseStatus,
    DerPaginate,
  },
  data() {
    return {
      DOMPURIFY_CONFIG_NAME,
      loading: true,
      hasMultipleFeatures: false,
      isAdvancedSearchOpen: false,
      products: this.filterProducts(),
      user: this.$store.state.user,
      searchOptions: {
        centers: [],
        users: [],
        userTypes: [],
        products: [],
        statuses: [],
        examinationsFilters: [],
        extraFilters: [],
      },
      selectedExtraFilters: [],
      selectedExtraCaseFiltersList: [],
      isSearching: false,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      searchFields: {
        query: "",
        dateFrom: "",
        dateTo: "",
        tags: "",
        center: "",
        plannedDiagnosisId: "",
        dermatoscopicDiagnosisId: "",
        histopathologicalDiagnosisId: "",
        user: "",
        userType: null,
        products: null,
        status: null,
        mainFilter: null,
        sort: {
          field: "createdAt",
          orderBy: "DESC",
        },
        flagged: "",
        prioritized: "",
        bookmarked: "",
        unreadMessages: "",
        unreadPrivateMessages: "",
        aiPrioritized: "",
        page: 1,
      },
      examinations: [],
      sortBySelection: "createdAt",
      userHasNoProducts: false,
      userHasNoProductsMessage: this.trans(
        "errorMessages.userHasNoActiveProducts",
      ),
      moleDiagnosisFieldsVisible: false,
      productsWithPermission: {},
      PRODUCT_ID: PRODUCT_ID,
    };
  },
  computed: {
    userSingleProductId: function () {
      if (this.products) {
        if (this.products.length === 1) {
          return this.products[0].type;
        }
        return "";
      }
    },
    examinationNavigationDetails() {
      return {
        examinations: this.examinations.map((examination) => ({
          id: examination.id,
          editUrl: examination.editUrl,
        })),
        paginationInfo: { ...this.paginate },
      };
    },
    isExaminationsFiltersLoaded() {
      return (
        this.searchOptions.examinationsFilters &&
        this.searchOptions.examinationsFilters.length > 0
      );
    },
  },
  watch: {
    "searchFields.products": function () {
      // trigger check on filter selection
      if (this.searchFields.products) {
        this.productHasDiagnosisSearchFields(this.searchFields.products);
      }
    },
  },
  created: function () {
    this.setQueryFields(true);
    this.getResults();
    this.setProductSpecificSearchFields();
  },
  methods: {
    getResults: function (page) {
      this.loading = true;

      this.searchFields.page = page ? page : this.searchFields.page;

      localStorage.setItem("last_query_uri", JSON.stringify(this.searchFields));

      axios
        .get(
          "/api/internal/examinations?" + queryStringBuilder(this.searchFields),
        )
        .then((data) => {
          this.loading = false;
          let collection = data.data;
          if (collection.error) {
            this.userHasNoProducts = true;
          } else {
            this.examinations = collection.data;
            this.searchOptions = collection.searchOptions;
            this.hasMultipleFeatures = collection.hasMultipleFeatures;
            this.paginate = collection.paginate;

            if (!this.hasMultipleFeatures && this.products.length !== 0) {
              this.searchFields.products = this.products[0].id;
            }

            this.setQueryFields();
          }
        })
        .catch((error) => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    selectExtraFilters: function (filtersList) {
      const oldSelectedExtraFilters = this.selectedExtraFilters;

      this.selectedExtraFilters = filtersList;

      const newSelectedExtraFilters = this.selectedExtraFilters;

      this.selectedExtraCaseFiltersList = filtersList;

      oldSelectedExtraFilters.forEach((el) => {
        if (
          newSelectedExtraFilters.length !== 0 &&
          newSelectedExtraFilters.includes(el.value)
        ) {
          return;
        } else {
          // if element is not in the new list, assign ""
          this.searchFields[el.value] = "";
        }
      });

      // assign 1 to all elements in the new list
      newSelectedExtraFilters.forEach((element) => {
        this.searchFields[element.value] = 1;
      });

      this.$notify({
        text: this.trans("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });

      this.getResults(1);
    },
    selectProduct: function (product) {
      this.searchFields.products = product;

      // these props either don't exist on all or differ between products
      this.searchFields.plannedDiagnosisId = "";
      this.searchFields.dermatoscopicDiagnosisId = "";
      this.searchFields.histopathologicalDiagnosisId = "";
      this.searchFields.tags = "";
    },
    sortBy: function (field, event) {
      this.loading = true;
      this.sortBySelection = field;

      // Reset all to bottom
      if (field !== this.searchFields.sort.field) {
        $("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");

        this.searchFields.sort.orderBy = "DESC";
      }

      this.searchFields.sort.field = field;

      if (this.searchFields.sort.orderBy == "ASC") {
        this.searchFields.sort.orderBy = "DESC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");
      } else {
        this.searchFields.sort.orderBy = "ASC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-down")
          .removeClass("fa-caret-down")
          .addClass("fa-caret-up");
      }

      localStorage.setItem("last_query_uri", JSON.stringify(this.searchFields));

      this.getResults(this.currentPage);
    },
    resetAllSortBy: function () {
      $("th.sort-by")
        .find("i.fas.fa-caret-up")
        .removeClass("fa-caret-up")
        .addClass("fa-caret-down");
      this.searchFields.sort.orderBy = "DESC";
    },
    clearSearch: function () {
      this.isSearching = false;
      this.isAdvancedSearchOpen = false;
      this.clearQuerySearch();
      this.getResults(1);
    },
    clearQuerySearch: function () {
      localStorage.removeItem("last_query_uri");
      localStorage.removeItem("last_visited_examination_id");

      // reset all fields, but keep filter selections
      this.searchFields = {
        query: "",
        dateFrom: "",
        dateTo: "",
        tags: "",
        center: "",
        plannedDiagnosisId: "",
        dermatoscopicDiagnosisId: "",
        histopathologicalDiagnosisId: "",
        user: "",
        products: null,
        status: null,
        mainFilter: this.searchFields.mainFilter,
        sort: {
          field: "createdAt",
          orderBy: "DESC",
        },
        flagged: this.searchFields.flagged ? this.searchFields.flagged : "",
        prioritized: this.searchFields.prioritized
          ? this.searchFields.prioritized
          : "",
        bookmarked: this.searchFields.bookmarked
          ? this.searchFields.bookmarked
          : "",
        unreadMessages: this.searchFields.unreadMessages
          ? this.searchFields.unreadMessages
          : "",
        unreadPrivateMessages: this.searchFields.unreadPrivateMessages
          ? this.searchFields.unreadPrivateMessages
          : "",
        aiPrioritized: this.searchFields.aiPrioritized
          ? this.searchFields.aiPrioritized
          : "",
        page: 1,
      };

      this.resetAllSortBy();
      $('select[name="center"]').val("").trigger("change");
      $('select[name="users"]').val("").trigger("change");
      $('select[name="status"]').val("").trigger("change");
      $('select[name="products"]').val("").trigger("change");
    },
    filter: function (filterStatus) {
      this.resetAllSortBy();

      this.searchFields.sort.field = "createdAt";
      this.searchFields.mainFilter = filterStatus;

      localStorage.setItem("last_query_uri", JSON.stringify(this.searchFields));

      this.$notify({
        text: this.trans("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });

      this.getResults(1);
    },
    // Event functions
    isLastOpend: function (examination) {
      return (
        localStorage.getItem("last_visited_examination_id") == examination.id
      );
    },
    openExamination: function (examination) {
      this.$router.push({
        path: "/consultations" + examination.editUrl,
        state: setHistoryState(
          "examinationNavigationDetails",
          this.examinationNavigationDetails,
        ),
      });
    },
    toggleAdvancedSearch: function () {
      if (this.isAdvancedSearchOpen) {
        this.isAdvancedSearchOpen = false;
        this.clearSearch();
      } else {
        this.isAdvancedSearchOpen = true;
        this.clearQuerySearch();
      }
    },
    setQueryFields: function (initializeFromLocalstorage) {
      const queryUri = localStorage.getItem("last_query_uri");
      this.isSearching = false;

      if (queryUri) {
        const parsedQueryUri = JSON.parse(queryUri);

        if (initializeFromLocalstorage) {
          this.searchFields = parsedQueryUri;
        }

        // assign extra filter values after page refresh
        let list = [];

        for (let key in this.searchFields) {
          this.searchOptions.extraFilters.find((e) => {
            if (e.value === key && this.searchFields[key] == 1) {
              list.push(e);
            }
          });
        }

        this.selectedExtraCaseFiltersList = list;
        this.selectedExtraFilters = list;

        if (this.searchFields.query.length > 0 && !this.isAdvancedSearchOpen) {
          this.isSearching = true;
        }

        // if advanced search filter is applied - advanced search is open and user can remove selection
        for (var key in this.searchFields) {
          let value = this.searchFields[key];

          if (
            value &&
            !inArray(key, [
              "sort",
              "query",
              "page",
              "mainFilter",
              "flagged",
              "prioritized",
              "bookmarked",
              "unreadMessages",
              "unreadPrivateMessages",
              "aiPrioritized",
            ])
          ) {
            this.isAdvancedSearchOpen = true;
            this.isSearching = true;
          }
        }
      }
    },
    filterProducts: function () {
      return this.$store.state.user.products;
    },
    productHasDiagnosisSearchFieldsPermission: async function (productId) {
      if (!this.productsWithPermission[productId]) {
        const product = await axios
          .get(`/api/internal/products/${productId}`)
          .then((data) => {
            return data.data;
          })
          .catch(() => {});

        if (product) {
          Object.assign(this.productsWithPermission, {
            [product.meta.id]: product,
          });

          return productHasPermission(
            PRODUCT_PERMISSIONS.USE_DIAGNOSIS_SE,
            product,
          );
        }
      } else {
        return productHasPermission(
          PRODUCT_PERMISSIONS.USE_DIAGNOSIS_SE,
          this.productsWithPermission[productId],
        );
      }
    },
    productHasDiagnosisSearchFields: async function (productId) {
      const productHasPermission =
        await this.productHasDiagnosisSearchFieldsPermission(productId);

      if (this.$can("view_mole_se_diagnosis") && productHasPermission) {
        return (this.moleDiagnosisFieldsVisible = true);
      }
      return (this.moleDiagnosisFieldsVisible = false);
    },
    setProductSpecificSearchFields: function () {
      // if user has only mole / skåne mole, they should see specific search fields straight away
      const moleProductIds = [
        getProductType(PRODUCTS.MOLE),
        getProductType(PRODUCTS.REGION_SKANE_MOLE),
      ];

      const singleProductId = moleProductIds.find((id) => {
        return this.userSingleProductId === id;
      });

      if (singleProductId)
        this.productHasDiagnosisSearchFields(singleProductId);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-search-bar,
.advanced-search {
  background-color: #f5f5f5;
}

.advanced-search {
  padding-top: 0px;
}

.consultations-list-icon {
  padding: 0px 2px;
}

.overlay-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 20px 0px;
  text-align: center;
  font-weight: bold;
}
</style>
