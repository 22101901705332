<template>
  <div class="card">
    <div class="card-header">{{ label }}</div>
    <div class="card-body">
      <div class="card-text col-md-12 tw-mb-2">
        <DerToggleWithLabel
          v-model="features[featureId].active"
          :label="label"
        />
      </div>
      <div v-if="features[featureId].active" class="card-text">
        <div
          v-for="option in features[featureId].config.options"
          :key="option"
          class="col-md-12"
        >
          <input
            v-model="features[featureId].config.selected"
            type="radio"
            :name="'footer-option-' + featureId"
            :value="option"
          />
          &nbsp;
          <label>{{ trans("feature.imageFooter" + option) }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    DerToggleWithLabel,
  },
  props: {
    center: {},
    features: {},
    featureId: {},
    label: String,
  },
};
</script>
