<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">{{ title }}</h3>
    </div>
    <div class="panel-body table-responsive">
      <table class="table table-hover interactive-table">
        <thead>
          <tr>
            <th>{{ trans("forms.hsaId") }}</th>
            <th>{{ trans("forms.email") }}</th>
            <th>{{ trans("forms.firstname") }}</th>
            <th>{{ trans("forms.lastname") }}</th>
            <th v-if="showAdminRoles">{{ trans("roles.adminRolesHeader") }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in users"
            :key="index"
            @click="$emit('user-click', user.id)"
          >
            <td>{{ user.hsa_id }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.firstname }}</td>
            <td>{{ user.lastname }}</td>
            <td v-if="showAdminRoles">{{ user.adminRoles }}</td>
            <td>
              <span
                v-if="user.blocked"
                :title="trans('forms.userCannotLoginTitle')"
              >
                ({{ trans("users.blockedUser") }})
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    users: Array,
    showAdminRoles: Boolean,
  },
};
</script>
