<template>
  <AsyncState :data-state="studiesState">
    <h5 class="mb-3">
      <TitleSelect
        :data-items="studies"
        :data-item-display="(item) => item.name"
        :data-placeholder="lang.get('view.research.selectedStudy.placeholder')"
        :data-selected-item="selectedItem"
        @update:data-selected-item="onUpdateDataSelectedItem"
      />
    </h5>
    <div v-if="!!selectedItem">
      <RouterNavTabs :data-items="itemNavs" />
      <RouterView />
    </div>
  </AsyncState>
</template>
<script setup lang="ts">
import { useAsyncState } from "@vueuse/core";
import AsyncState from "../../design-system/AsyncState.vue";
import { computed, ref, watch } from "vue";
import _find from "lodash/fp/find";
import { locationOf, ROUTES, useRouteOf } from "../routing/routes";
import { onBeforeRouteUpdate, useRouter } from "vue-router";
import { lang } from "../../i18n";
import TitleSelect from "../../design-system/TitleSelect.vue";
import { StudySummary } from "../../../../../app/Dermicus/Studies/StudySummary";
import RouterNavTabs from "../../design-system/RouterNavTabs.vue";
import { userStudiesAsAdminGet } from "../../store/core/users";
import { store } from "../../store";
import { checkStudiesAndRedirect } from "./check-studies-and-redirect";

const router = useRouter();
const route = useRouteOf<typeof ROUTES.researchAdmin>();
const selectedId = ref<number>();
watch(
  () => route.params.studyId,
  (value) => (selectedId.value = parseInt(value ?? "1", 10)),
  { immediate: true },
);

const studiesState = useAsyncState(
  () => userStudiesAsAdminGet(store.state.user.id),
  undefined,
);
const studies = computed(() => studiesState.state.value);
const selectedItem = computed(() =>
  _find({ id: selectedId.value }, studies.value),
);

const itemNavs = [
  {
    to: locationOf(ROUTES["researchAdmin.studyCases"]),
    display: lang.get("aiFeatures.researchAdminStudyCasesTabLabel"),
  },
  {
    to: locationOf(ROUTES["researchAdmin.studyParticipants"]),
    display: lang.get("aiFeatures.researchAdminStudyParticipantsTabLabel"),
  },
  {
    to: locationOf(ROUTES["researchAdmin.studyExport"]),
    display: "Export",
  },
];

function onUpdateDataSelectedItem(item: StudySummary) {
  router.replace({ params: { studyId: item.id.toString() } });
}

onBeforeRouteUpdate((to, from, next) => {
  checkStudiesAndRedirect(to, from, next);
});
</script>
