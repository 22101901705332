<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ trans("center.deactivateReactivateCenterLabel") }}
      </h3>
    </div>
    <div class="panel-body col-md-12">
      <div class="col-md-12">
        <p class="mb-3">
          {{ trans("center.deactivationInfoText") }}
        </p>

        <button
          v-if="isActive"
          class="btn btn-danger"
          @click="deactivateCenter"
        >
          {{ trans("center.deactivateCenter") }}
        </button>
        <button
          v-if="!isActive"
          class="btn btn-primary"
          @click="reactivateCenter"
        >
          {{ trans("center.reactivateCenter") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    center: Object,
  },
  computed: {
    isActive() {
      return this.center.deleted_at === null || !this.center.id;
    },
  },
  methods: {
    reactivateCenter() {
      axios
        .post("/api/internal/admin/centers/restore/" + this.center.id)
        .then(() => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.$emit("refresh");
        })
        .catch(this.userErrorMessage);
    },
    deactivateCenter() {
      this.$vueAlert
        .confirm(
          this.trans("center.deactivateCenterConfirmationMessage"),
          undefined,
          undefined,
          {
            confirmButtonText: this.trans("general.modalConfirmButton"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          },
        )
        .then(() => {
          axios
            .delete("/api/internal/admin/centers/" + this.center.id)
            .then(() => {
              this.$notify({
                text: this.trans("general.notificationSaved"),
                type: "success",
                duration: 1000,
              });
              this.$emit("refresh");
            })
            .catch(this.userErrorMessage);
        });
    },
  },
};
</script>
