<template>
  <TopBanner
    v-if="showBanner"
    :banner-header-text="platformMessageTranslation.subject"
    :banner-text="platformMessageTranslation.message"
    :banner-type="platformMessage.type"
    @close-top-banner="closeTopBanner"
  ></TopBanner>
  <login-form-container v-if="selectedAuthMethod" class="login-form-container">
    <div
      v-if="authMethodOptions.length > 1"
      class="d-flex justify-content-center my-4"
    >
      <SegmentedSwitch
        v-model:selectedOption="selectedAuthMethod"
        :options="authMethodOptions"
      />
    </div>

    <p v-if="showSmsIntro" class="login-intro container">
      {{ trans("auth.login.sms") }}
    </p>

    <p v-if="showSithsIntro" class="login-intro container">
      {{ trans("auth.login.siths") }}
    </p>

    <div class="account-content-login">
      <sms-login
        v-if="
          selectedAuthMethod === 'username-password:sms' ||
          selectedAuthMethod === 'username-password:auth-app'
        "
        :method="selectedAuthMethod"
      />

      <div v-if="selectedAuthMethod === 'siths'" class="container my-3">
        <router-link to="/auth/siths" class="btn btn-primary btn-block btn-lg">
          {{ trans("general.authentication-method.siths") }}
        </router-link>
      </div>

      <div v-if="selectedAuthMethod === 'jonkoping'" class="container my-3">
        <router-link
          to="/auth/svensk-e-identitet/jonkoping"
          class="btn btn-primary btn-block btn-lg"
        >
          {{ trans("general.authentication-method.jonkoping") }}
        </router-link>
      </div>
    </div>
  </login-form-container>
</template>

<script setup>
import LoginFormContainer from "./LoginFormContainer.vue";
import SmsLogin from "./Sms.vue";
import HorizontalLine from "../Utils/HorizontalLine.vue";
import SegmentedSwitch from "../Layout/SegmentedSwitch.vue";
import { ref, computed, watch } from "vue";
import { useAsyncState } from "@vueuse/core";
import { lang } from "../../i18n";
import { appConfigGet } from "../../app/practitioner-portal/store/app-config";
import BankIDLogo from "../../../images/BankID_logo_white.svg";
import TopBanner from "../Utils/TopBanner.vue";

const selectedAuthMethod = ref();
const lastUsedAuthMethod = localStorage.getItem("auth_method");
const languages = ["de", "en", "fr", "it", "nl", "sv"];

const { state: appConfig } = useAsyncState(appConfigGet(), undefined, {
  onSuccess: (data) => {
    lang.setLocale(browserOrFallbackLocale.value);
    selectedAuthMethod.value =
      data.data.authMethods.find((method) => method === lastUsedAuthMethod) ??
      data.data.authMethods[0];
  },
});

const authMethodOptions = computed(() => {
  return appConfig.value.data.authMethods.map((method) => ({
    title: lang.get(`general.authentication-method.${method}`),
    value: method,
  }));
});

watch(selectedAuthMethod, (method) => {
  localStorage.setItem("auth_method", method);
});

const showSithsIntro = computed(() => {
  return (
    authMethodOptions.value.length > 1 && selectedAuthMethod.value === "siths"
  );
});

const showSmsIntro = computed(() => {
  return (
    authMethodOptions.value.length > 1 &&
    (selectedAuthMethod.value === "username-password:sms" ||
      selectedAuthMethod.value === "username-password:auth-app")
  );
});

const browserOrFallbackLocale = computed(() => {
  let browserLocale = navigator.language.split("-")[0];
  let platformLocale = appConfig.value?.data.platform.locale ?? "en";
  return languages.includes(browserLocale) ? browserLocale : platformLocale;
});

const platformMessage = computed(() => {
  return appConfig.value?.data.platformMessage ?? false;
});

const platformMessageTranslation = computed(() => {
  if (platformMessage.value && platformMessage.value.translations) {
    return (
      platformMessage.value.translations.find(
        (translation) => translation.locale === browserOrFallbackLocale.value,
      ) ?? platformMessage.value.translations[0]
    );
  }
});

const showBanner = computed(() => {
  //Temporarily uncomment this to clear localstorage
  // localStorage.setItem("seen_platform_messages", []);
  return (
    platformMessage.value &&
    !seenPlatformMessages().includes(platformMessage.value.id)
  );
});

const seenPlatformMessages = () => {
  return localStorage.getItem("seen_platform_messages") ?? [];
};

const closeTopBanner = () => {
  localStorage.setItem("seen_platform_messages", [
    ...seenPlatformMessages(),
    platformMessage.value.id,
  ]);
};
</script>
<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.login-form-container {
  position: relative;
}
</style>
