<template>
  <div>
    <portal to="lightbox-container">
      <MultiLightbox
        v-if="showImageCompare"
        :images="selectedImg.map((img) => ({ id: img.id, url: img.uri }))"
        @close-event="closeImageCompare"
      />
    </portal>

    <div class="tw-inline-block">
      <strong class="tw-mr-2">{{
        trans("notifications.compareImagesTitle")
      }}</strong>
      <InfoPopup :text-content="trans('notifications.compareImagesInfoText')" />
    </div>

    <div class="row scrolling-wrapper my-2">
      <div
        v-for="(examination, index) in collection"
        :key="index"
        class="col-md-4 col-lg-3 col-sm-4"
      >
        <div class="panel panel-default">
          <div class="panel-heading">
            <div class="tw-flex tw-justify-between">
              <h3 class="panel-title">
                {{ examination.createdAt }}
              </h3>
              <RouterLink
                :to="examination.editUrl"
                class="btn"
                :title="trans('examination.visitCase')"
              >
                <i class="far fa-external-link"></i>
              </RouterLink>
            </div>
            <span>{{ examination.product }}</span>
          </div>
          <div class="panel-body">
            <div
              v-for="(image, index) in getFilteredImages(examination.images)"
              :key="index"
              class="col-md-12 mt-2"
            >
              <label>{{ image.label }}</label>
              <div
                v-if="image.id in encodedImages"
                class="round"
                :title="trans('forms.chooseImgToCompareTitle')"
              >
                <input
                  :id="image.id"
                  v-model="selectedImg"
                  type="checkbox"
                  :value="{
                    id: image.id,
                    uri: encodedImages[image.id],
                  }"
                />
                <label :for="image.id" />
              </div>
              <div>
                <EditImage
                  :data-image-id="image.id"
                  :data-examination-id="examination.id"
                  @edit-saved="handleEditSaved"
                >
                  <div @click="handleImageClick(image)">
                    <encoded-image
                      ref="encodedImage"
                      :image-id="image.id"
                      style-class="img-fluid lightbox-thumbnail"
                      :case-id="examination.id"
                      :uri="image.uri"
                      @on-receive-encoded-image="onEncodedImageLoaded"
                    />
                  </div>
                </EditImage>
              </div>
            </div>
          </div>
        </div>
      </div>
      <slot />
      <div
        v-if="
          totalExamination > 5 &&
          paginate.current_page !== paginate.last_page &&
          !externalCollection
        "
        class="col-md-3 load-more"
      >
        <div
          class="load btn btn-primary col-12 mb-3"
          @click="loadMoreExaminations()"
        >
          {{ trans("examination.imageCompoareLoadMoreButton") }}
        </div>
        <clip-loader v-if="loading" />
      </div>
    </div>
    <portal to="lightbox-container">
      <Lightbox
        v-if="activeImage"
        v-model:activeImage="activeImage"
        :images="lightboxImages"
        @close-event="handleClose"
      />
    </portal>
  </div>
</template>

<script>
import EncodedImage from "./EncodedImage.vue";
import { filterImagesByProduct } from "../../../helpers/product";
import Lightbox from "../../Layout/Lightbox.vue";
import InfoPopup from "../../Utils/InfoPopup.vue";
import { RouterLink } from "vue-router";
import EditImage from "./EditImage.vue";
import MultiLightbox from "../../Layout/MultiLightbox.vue";

export default {
  components: {
    EncodedImage,
    Lightbox,
    InfoPopup,
    RouterLink,
    EditImage,
    MultiLightbox,
  },
  props: {
    examinationId: Number,
    type: Number,
    loadOnMount: Boolean,
    productName: String,
    externalCollection: Array,
  },

  data() {
    return {
      loading: false,
      collection: [],
      paginate: {},
      totalExamination: 0,
      images: {},
      selectedImg: [],
      showImageCompare: false,
      encodedImages: {},
      activeImage: false,
      lightboxImages: [],
    };
  },
  watch: {
    selectedImg: function () {
      if (this.selectedImg.length === 2) {
        this.showImageCompare = true;
      } else if (this.selectedImg.length > 2) {
        this.showImageCompare = false;
        this.selectedImg.splice(0, 2);
      }
    },
    externalCollection: function (newCollection) {
      if (newCollection && newCollection.length > 0) {
        this.collection = newCollection;
      }
    },
    collection(consultations) {
      this.lightboxImages = consultations.flatMap((consultation) =>
        Object.values(this.getFilteredImages(consultation.images)).map(
          (image) => ({
            id: image.id,
            url: "",
          }),
        ),
      );
    },
  },
  mounted: function () {
    if (this.externalCollection && this.externalCollection.length > 0) {
      this.collection = this.externalCollection;
    }
  },
  methods: {
    handleEditSaved(payload) {
      const imageRef = this.$refs.encodedImage.find(
        (img) =>
          img.imageId === payload.imageId &&
          img.caseId === payload.examinationId,
      );
      if (imageRef) {
        imageRef.getImage();
      }
    },
    handleImageClick(image) {
      this.activeImage = this.activeImage
        ? false
        : this.lightboxImages.find((img) => parseInt(img.id) === image.id);
    },
    handleClose() {
      this.activeImage = false;
    },
    onEncodedImageLoaded: function (event) {
      this.encodedImages = {
        ...this.encodedImages,
        [event.imageId]: event.uri,
      };
      this.lightboxImages = this.lightboxImages.map((img) => {
        if (img.id === event.imageId) {
          return {
            ...img,
            url: event.uri,
          };
        }

        return img;
      });
    },
    getFilteredImages: function (images) {
      return filterImagesByProduct(images, this.type);
    },
    loadMoreExaminations() {
      this.loading = true;
      axios
        .get(this.paginate.next_page_url + "&type=" + this.type)
        .then((response) => {
          let imgFromResponse = response.data.data;

          if (imgFromResponse) {
            // TODO test with many cases!
            this.collection.push.apply(this.collection, imgFromResponse);
          }
          this.paginate = response.data.paginate;
          this.loading = false;
        })
        .catch((error) => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    closeImageCompare: function () {
      this.showImageCompare = false;
      this.selectedImg = [];
    },
    getImages: function () {
      let editSaveUri =
        "/api/internal/image-comparer/" +
        this.examinationId +
        "?type=" +
        this.type +
        "&page=1";
      if (this.collection.length === 0) {
        axios
          .get(editSaveUri)
          .then((response) => {
            this.collection = response.data.data;
            this.paginate = response.data.paginate;
            this.totalExamination = response.data.totalExamination;
          })
          .catch((error) => {
            this.userErrorMessage(error);
          });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

h3.panel-title {
  padding: 10px 0;
}

.panel-heading > a > .far.fa-external-link {
  color: #7a7d84 !important;
}

span.clear {
  clear: both;
  display: block;
  padding: 0px;
}

.round {
  position: relative;
  float: right;
}

.round label {
  background-color: #ffffff;
  border: 2px solid #cecece;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 28px;
  z-index: 1;
}

.round label:after {
  border: 2px solid variables.$dermicusBlue;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  border-color: variables.$dermicusBlue;
}

.round input[type="checkbox"]:checked + label:after,
.round input[type="checkbox"] + label:before {
  opacity: 1;
}

.alert-info {
  color: #273d5e;
  background-color: #ccd5ec;
  border-color: #ccd5ec;
}
</style>
