import Bugsnag from "@bugsnag/js";
import { datadogRum } from "@datadog/browser-rum";

import { createApp } from "vue";
import router from "./routes";
import "../sass/app.scss";
import { applyDesignSystem } from "./design-system/config/apply-design-system";
import Notifications from "./components/Utils/notifications";
import { practitionerHttpService } from "./app/practitioner-portal/store/practitioner-http-service";

// polling data every minute (in milliseconds)
const TOKEN_EXPIRATION_POLLING_INTERVAL = 60 * 1000;
const ALLOWED_ERRORS_IN_SEQUENCE = 3;

export const app = applyDesignSystem(
  createApp({
    data: function () {
      return {
        polling: null,
      };
    },
    computed: {
      isAuthenticated: function () {
        return this.$store.state.isAuthenticated;
      },
    },
    watch: {
      "$store.state.user": function (user) {
        Bugsnag.isStarted() && Bugsnag.setUser((user && user.id) || null);

        if (user && user.id) {
          datadogRum.setUser({
            id: user.id,
          });
        }
      },
    },
    mounted: function () {
      this.$store.state.isSplashScreen = false;
    },
    beforeUnmount: function () {
      clearInterval(this.polling);
    },
    created: function () {
      this.pollData();
    },
    methods: {
      logoutUser: function () {
        this.$router.push({ name: "Logout" });
      },
      pollData: function () {
        let errorsInSequence = 0;
        this.polling = setInterval(() => {
          if (this.isAuthenticated) {
            practitionerHttpService
              .get("/api/internal/auth/token-expiration")
              .then((response) => {
                if (!response.data.error_code) {
                  if (errorsInSequence > 0) {
                    Notifications.success(
                      this.trans(
                        "errorMessages.connectivityIssuesResolved.message",
                      ),
                      this.trans(
                        "errorMessages.connectivityIssuesResolved.heading",
                      ),
                    );
                  }
                  errorsInSequence = 0;
                  let messageTime = moment().format("HH:mm:ss A");
                  let message = {
                    title: this.trans("auth.sessionEndingMessageHeader"),
                    body:
                      `[${messageTime}] - ` +
                      this.trans("auth.sessionEndingMessage"),
                  };

                  // when 5 minutes of active session is left, show info dialog
                  if (response.data?.expires_in_minutes === 5) {
                    this.$vueAlert
                      .alert(
                        message.body,
                        message.title,
                        undefined,
                        undefined,
                        {
                          confirmButtonText: this.trans(
                            "auth.continueUsingSystem",
                          ),
                          customClass: "logout-alert",
                        },
                      )
                      .then(() => {
                        // on accept dialog prolong the session
                        practitionerHttpService.get(
                          "/api/internal/auth/information",
                        );
                      });
                  }
                } else {
                  if (response.data.error_code === 401) {
                    this.logoutUser();
                  }
                }
              })
              .catch(() => {
                if (errorsInSequence > ALLOWED_ERRORS_IN_SEQUENCE) {
                  this.logoutUser();
                } else {
                  errorsInSequence++;
                  Notifications.error(
                    this.trans("errorMessages.connectivityIssues.message"),
                    this.trans("errorMessages.connectivityIssues.heading"),
                  );
                }
              });
          } else {
            // no polling if user if not authenticated
            return;
          }
        }, TOKEN_EXPIRATION_POLLING_INTERVAL);
      },
    },
    template: "<router-view />",
    router,
  }),
);
