<template>
  <div v-if="visible" class="top-banner" :class="bannerClass">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <i :class="infoIcon()" class="mr-4" />
          <div>
            <h6>{{ bannerHeaderText }}</h6>
            <p v-if="bannerText">{{ bannerText }}</p>
            <div
              v-if="bannerHTMLContent"
              v-dompurify-html="bannerHTMLContent"
              class="banner-html-content"
            />
          </div>
        </div>
        <button type="button" class="close" @click="closeBanner">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerHeaderText: String,
    bannerText: String,
    bannerType: String,
    bannerHTMLContent: String,
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    bannerClass() {
      switch (this.bannerType) {
        case "warning":
          return "banner-warning";
        case "error":
          return "banner-error";
        default:
          return "banner-info";
      }
    },
  },
  methods: {
    closeBanner() {
      this.visible = false;
      this.$emit("closeTopBanner");
    },
    infoIcon() {
      switch (this.bannerType) {
        case "warning":
          return "fal fa-exclamation-triangle warning";
        case "error":
          return "fal fa-times-circle error";
        default:
          return "fal fa-info info";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.top-banner {
  width: 100%;
  padding: 1rem;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    font-size: 2rem;
  }

  &.banner-info {
    background-color: variables.$dermicusBlue;
    color: #fff;
  }

  &.banner-warning {
    background-color: variables.$dermicusYellow;
    color: #fff;
  }

  &.banner-error {
    background-color: variables.$dermicusRed;
    color: #fff;
  }

  .banner-html-content {
    text-align: left;
    h1 {
      font-size: 1.2rem;
    }

    p {
      font-weight: 300;
      line-height: 1.6;
    }
  }

  .close {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #fff;
  }
}
</style>
