<template>
  <div class="card">
    <div class="card-header">Excluded mobile authentication methods</div>
    <div class="card-body">
      <div class="card-text">
        <div
          v-for="method in mobileAuthMethods"
          :key="method"
          class="form-check"
        >
          <input
            class="form-check-input"
            :checked="selectedMobileMethods.includes(method)"
            type="checkbox"
            @change="toggleMobileMethod(method)"
          />
          <label class="form-check-label" :for="method">{{
            trans(`general.authentication-method.${method}`)
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";

export default {
  props: {
    features: {},
    featureId: {},
    region: {},
  },
  data() {
    return {
      mobileAuthMethods: [],
      selectedMobileMethods: [],
    };
  },
  created() {
    this.fetchAuthMethods();
    this.selectedMobileMethods = [
      ...(this.features[this.featureId].config || []),
    ];
  },
  methods: {
    fetchAuthMethods() {
      practitionerHttpService
        .get("/api/internal/admin/auth-methods")
        .then((response) => {
          this.mobileAuthMethods = response.data.mobile_auth_methods;
        });
    },
    toggleMobileMethod(method) {
      if (this.selectedMobileMethods.includes(method)) {
        this.selectedMobileMethods = this.selectedMobileMethods.filter(
          (m) => m !== method,
        );
      } else {
        this.features[this.featureId].active = true;
        this.selectedMobileMethods.push(method);
      }
      this.features[this.featureId].config = [...this.selectedMobileMethods];
    },
  },
};
</script>
