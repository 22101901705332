import Lang from "lang.js";
import de from "../../../lang/de.json";
import dev from "../../../lang/dev.json";
import en from "../../../lang/en.json";
import fr from "../../../lang/fr.json";
import it from "../../../lang/it.json";
import nl from "../../../lang/nl.json";
import sv from "../../../lang/sv.json";
import { shallowReadonly, shallowRef, watch } from "vue";

const LOCALE_KEY = "lang_code";

/**
 * Although `lang.js` maps the Laravel i18n functionality to the FE, I believe
 * it's not keeping the Laravel JSON support in mind, and thus can't work with
 * the source JSON files out-of-the-box, which is a pity. The lib to transform
 * messages to this expected format was removed, as we thought it was the same
 * JSON as Laravel uses, and to be able to have a setup without tooling. Thus,
 * we'll have to convert here to the format expected. We definitely should use
 * better i18n libraries out there like i18next, which works everywhere.
 */
const addMessagesFor = (lang, messages, allMessages) => {
  Object.entries(messages).forEach(([fullKey, value]) => {
    const { ns, key } = /^(?<ns>[^.]+?)\.(?<key>.+)$/.exec(fullKey).groups;
    const nsKey = `${lang}.${ns}`;
    allMessages[nsKey] = allMessages[nsKey] || {};
    allMessages[nsKey][key] = value;
  });
};
const messages = {};
addMessagesFor(`de`, de, messages);
addMessagesFor(`dev`, dev, messages);
addMessagesFor(`en`, en, messages);
addMessagesFor(`fr`, fr, messages);
addMessagesFor(`it`, it, messages);
addMessagesFor(`nl`, nl, messages);
addMessagesFor(`sv`, sv, messages);

/**
 * @deprecated use "lang" instead, which provides the Laravel i18n features.
 */
window["i18n"] = messages;

const localeRef = shallowRef(localStorage.getItem(LOCALE_KEY) ?? "en");

/**
 * Laravel i18n functionality for JavaScript.
 *
 * @see {@link https://laravel.com/docs/9.x/localization}
 * @see {@link https://github.com/rmariuzzo/lang.js}
 * @see {@link https://github.com/rmariuzzo/Laravel-JS-Localization}
 * @see {@link https://github.com/GnoscoAB/dermicus-platform-2/tree/develop/docs/i18n-and-l10n.md}
 */
export const lang = new Lang({
  messages,
  fallback: "dev",
  locale: localeRef.value,
});

export const currentLocale = shallowReadonly(localeRef);

const ogSetLocale = lang.setLocale.bind(lang);
lang.setLocale = function setLocale(locale) {
  localStorage.setItem(LOCALE_KEY, locale);
  ogSetLocale(locale);
  localeRef.value = locale;
};

// Some existing code depends on the return value we had before, which was
// `undefined` when there was no translation. Even though that code wasn't
// functionally good, to make sure we don't break any other code, we'll deviate
// from the standard Laravel i18n API here on the return value. That said, it's
// a wider spread default to return `undefined` in many i18n libraries in cases
// where there is no translations, especially when working with a `dev` set.
const ogGet = lang.get.bind(lang);
lang.get = function get(key, ...args) {
  const value = ogGet(key, ...args);
  return value === key ? undefined : value;
};
