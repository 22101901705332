<template>
  <tr>
    <td>
      <DerBadge
        :data-variant="isConnected ? 'success' : 'danger'"
        :data-value="
          isConnected
            ? lang().get('model.studyConsentStatus.connected.display')
            : lang().get('model.studyConsentStatus.disconnected.display')
        "
      />
    </td>
    <td>{{ study.name }}</td>
    <td class="tw-text-right tw-w-[200px]">
      <Button :is-loading="loading" @click="toggleConnection">
        {{
          isConnected
            ? lang().get("action.study.adminRemove.label")
            : lang().get("action.study.adminAdd.label")
        }}
      </Button>
    </td>
  </tr>
</template>

<script>
import DerBadge from "../../../design-system/DerBadge.vue";
import Button from "../../Layout/Button.vue";
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";
import { lang } from "../../../i18n";

export default {
  components: {
    DerBadge,
    Button,
  },
  props: {
    centerId: {
      type: Number,
      required: true,
    },
    study: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isConnected() {
      return this.study.isConnected;
    },
  },
  methods: {
    lang() {
      return lang;
    },
    async toggleConnection() {
      this.loading = true;
      try {
        if (!this.isConnected) {
          await practitionerHttpService.post(
            `/api/internal/centers/${this.centerId}/consent-studies/${this.study.id}/`,
          );
          this.study.isConnected = true;
        } else {
          await practitionerHttpService.delete(
            `/api/internal/centers/${this.centerId}/consent-studies/${this.study.id}/`,
          );
          this.study.isConnected = false;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
