<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">{{ trans("patient.patientLookupType") }}</h3>
    </div>
    <div class="panel-body">
      <select v-model="center.patient_lookup_type" class="form-control">
        <option :value="null">
          {{ trans("center.patientLookupTypeNull") }}
        </option>
        <option
          v-for="type in patientLookupTypes"
          :key="type.id"
          :value="type.id"
        >
          {{ type.display }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { practitionerHttpService } from "../../../app/practitioner-portal/store/practitioner-http-service";

export default {
  props: {
    center: Object,
  },
  setup() {
    const patientLookupTypes = ref([]);

    async function loadPatientLookupTypes() {
      try {
        const response = await practitionerHttpService.get(
          "/api/internal/admin/patient-lookup-types",
        );
        patientLookupTypes.value = response.data;
      } catch (error) {
        console.error("Error loading patient lookup types:", error);
      }
    }

    onMounted(loadPatientLookupTypes);

    return {
      patientLookupTypes,
    };
  },
};
</script>
