<template>
  <div v-for="error in errorMessage" class="help-block mb-2 validation-error">
    <strong>{{ error }}</strong>
  </div>
</template>

<script>
export default {
  props: ["errors"],
  computed: {
    errorMessage: function () {
      if (Array.isArray(this.errors)) {
        return this.errors;
      } else {
        return [this.errors];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

div.validation-error {
  color: variables.$dermicusRed;
}
</style>
