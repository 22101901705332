<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ trans("users.tabMenuLabelProducts") }}
      </h3>
    </div>
    <div class="panel-body">
      <div
        v-if="Object.keys(products).length > 0"
        class="row col-md-12 tw-gap-y-8"
      >
        <div
          v-for="(product, key) in products"
          :key="key"
          class="col-md-2 col-sm-3"
        >
          <DerToggleWithLabel
            v-model="product.active"
            :label="product.display_name"
          />

          <div v-if="product.active">
            <div
              v-for="(field, fieldKey) in product.config.defaults"
              :key="fieldKey"
            >
              <der-select
                v-model="field.selectedOption"
                :data-options="field.options"
                :data-get-option-value="(option) => option"
                :data-get-option-label="(option) => option.label"
                :data-placeholder="
                  trans('examination.center_defaults_product_default') +
                  ', ' +
                  trans(`examination.${field.fieldName}`).toLowerCase()
                "
                class="mt-2"
                @input="handleDropdownChange(field, $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";
import DerSelect from "../../Utils/DerSelect.vue";

export default {
  components: {
    DerToggleWithLabel,
    DerSelect,
  },
  props: {
    products: Object,
    center: Object,
  },
  data() {
    return {
      defaultOption: { value: "", label: "Select an option" },
    };
  },
  methods: {
    handleDropdownChange(field, event) {
      const selectedOption = field.options.find(
        (option) => option.value === event?.value,
      );

      if (selectedOption) {
        field.options.forEach((option) => {
          option.selected = false;
        });
        selectedOption.selected = true;
        field.selectedOption = selectedOption;
      }
    },
  },
};
</script>
