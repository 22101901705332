<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ trans("menu.settings") }}
      </h3>
    </div>
    <div class="panel-body col-md-12">
      <div class="row col-md-12 my-3 tw-gap-y-8">
        <div
          v-for="feature in simpleFeatures"
          :key="feature.id"
          class="col-md-2 col-sm-3"
        >
          <DerToggleWithLabel
            v-model="features[feature.id].active"
            :label="formatFeatureLabel(feature.name)"
            :disabled="center.id && !center.active"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div
        v-for="feature in featuresWithOwnComponents"
        :key="feature.id"
        class="col-md-6 mb-4"
      >
        <component
          :is="featureComponents[feature.name]"
          :key="feature.id"
          :center="center"
          :region="region"
          :feature-id="feature.id"
          :features="features"
          :label="formatFeatureLabel(feature.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageFooter from "./ImageFooter.vue";
import Webdoc from "./Webdoc.vue";
import Ucr from "./Ucr.vue";
import DefaultReserveNumberPrefix from "./DefaultReserveNumberPrefix.vue";
import ExcludedMobileAuthenticationMethods from "./ExcludedMobileAuthenticationMethods.vue";
import ExcludedWebAuthenticationMethods from "./ExcludedWebAuthenticationMethods.vue";
import RequestStudyConsent from "./RequestStudyConsent.vue";
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    DerToggleWithLabel,
    Webdoc,
    Ucr,
    DefaultReserveNumberPrefix,
    ExcludedMobileAuthenticationMethods,
    ExcludedWebAuthenticationMethods,
    RequestStudyConsent,
    ImageFooter,
  },
  props: {
    features: {
      type: Object,
      default: () => ({}),
    },
    center: {
      type: Object,
      default: () => ({}),
    },
    region: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      featureComponents: {
        Webdoc,
        Ucr,
        DefaultReserveNumberPrefix,
        ExcludedWebAuthenticationMethods,
        ExcludedMobileAuthenticationMethods,
        RequestStudyConsent,
        ImageFooter,
      },
    };
  },
  computed: {
    simpleFeatures() {
      return Object.values(this.features).filter(
        (feature) => !this.featureComponents[feature.name],
      );
    },
    featuresWithOwnComponents() {
      return Object.values(this.features).filter(
        (feature) => this.featureComponents[feature.name],
      );
    },
  },
  methods: {
    formatFeatureLabel(name) {
      const key = "feature." + name.charAt(0).toLowerCase() + name.slice(1);
      return this.trans(key);
    },
  },
};
</script>
