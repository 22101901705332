<template>
  <div>
    <header-admin :active-section="10" />
    <div class="text-right my-2">
      <button class="btn btn-primary" @click="createNewMessage">
        {{ trans("admin.platformMessages.createNewMessage") }}
      </button>
    </div>

    <div v-if="!showForm" class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">{{ trans("admin.platformMessages.title") }}</h3>
      </div>
      <div class="panel-body">
        <div class="table-responsive">
          <clip-loader v-show="loading" />

          <table class="table table-hover interactive-table">
            <thead class="sortable-table-header">
              <tr>
                <th>{{ trans("admin.platformMessages.period") }}</th>
                <th>{{ trans("admin.platformMessages.stage") }}</th>
                <th>{{ trans("admin.platformMessages.interface") }}</th>
                <th>{{ trans("admin.platformMessages.type") }}</th>
                <th>{{ trans("admin.platformMessages.translations") }}</th>
                <th>{{ trans("admin.platformMessages.actions") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="message in messages" :key="message.id">
                <td>{{ message.start_date }} - {{ message.end_date }}</td>
                <td>{{ message.stage }}</td>
                <td>{{ message.interface }}</td>
                <td>{{ message.type }}</td>
                <td>
                  <span
                    v-for="(translation, index) in message.translations"
                    :key="translation.id"
                    :title="translation.subject + ' - ' + translation.message"
                    ><strong
                      >{{ translation.locale
                      }}{{
                        message.translations.length === index + 1 ? "" : ", "
                      }}
                    </strong>
                  </span>
                </td>
                <td>
                  <button
                    class="btn btn-primary mr-4"
                    @click="editMessage(message)"
                  >
                    {{ trans("general.edit") }}
                  </button>
                  <button
                    class="btn btn-primary mr-4"
                    @click="deleteMessage(message.id)"
                  >
                    {{ trans("general.formDeleteLabel") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="showForm" class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">{{ formTitle }}</h3>
      </div>
      <div class="panel-body">
        <form @submit.prevent="saveMessage">
          <div class="container-fluid">
            <div class="form-group mb-4">
              <div class="col mb-3">
                <label class="input-label mr-2">
                  {{ trans("admin.platformMessages.stage") }}:
                </label>
                <select v-model="form.stage" required class="mr-5">
                  <option
                    v-for="option in formData.stages"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
                <validation-error
                  v-if="!!formErrors?.stage?.length"
                  :errors="formErrors?.stage[0]"
                />
              </div>
              <div class="col mb-3">
                <label class="input-label mr-2">
                  {{ trans("admin.platformMessages.interface") }}:
                </label>
                <select v-model="form.interface" required class="mr-5">
                  <option
                    v-for="option in formData.interfaces"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
                <validation-error
                  v-if="!!formErrors?.interface?.length"
                  :errors="formErrors?.interface[0]"
                />
              </div>
              <div class="col mb-3">
                <label class="input-label mr-2">
                  {{ trans("admin.platformMessages.type") }}:
                </label>
                <select v-model="form.type" required class="mr-5">
                  <option
                    v-for="option in formData.types"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
                <validation-error
                  v-if="!!formErrors?.type?.length"
                  :errors="formErrors?.type[0]"
                />
              </div>
            </div>
            <div class="form-group mb-2">
              <div class="col mb-3">
                <label class="input-label mr-2">
                  {{ trans("admin.platformMessages.startDate") }}:
                </label>
                <input
                  v-model="form.start_date"
                  type="datetime-local"
                  required
                  class="mr-5"
                />
                <validation-error
                  v-if="!!formErrors?.start_date?.length"
                  :errors="formErrors?.start_date[0]"
                />
              </div>
              <div class="col mb-3">
                <label class="input-label mr-2">
                  {{ trans("admin.platformMessages.endDate") }}:
                </label>
                <input
                  v-model="form.end_date"
                  type="datetime-local"
                  required
                  class="mr-5"
                />
                <validation-error
                  v-if="!!formErrors?.end_date?.length"
                  :errors="formErrors?.end_date[0]"
                />
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary m-2 mb-4"
            type="button"
            @click="addTranslation"
          >
            {{ trans("admin.platformMessages.addTranslation") }}
          </button>
          <div>
            <div
              v-for="i in Math.ceil(numberOfTranslations / columns)"
              class="row"
            >
              <div
                v-for="translation in form.translations.slice(
                  (i - 1) * columns,
                  i * columns,
                )"
                class="card col p-0 m-3"
              >
                <div v-if="form.translations.length" class="card-header">
                  <label class="input-label mr-2">
                    {{ trans("users.languageLabel") }}:
                  </label>
                  <select v-model="translation.locale" required>
                    <option
                      v-for="option in formData.locales"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                  <validation-error
                    v-if="
                      formErrors?.translations?.length &&
                      formErrors?.translations[i - 1]?.locale?.length &&
                      !!formErrors?.translations[i - 1]?.locale[0]
                    "
                    :errors="formErrors?.translations[i - 1].locale"
                  />
                </div>
                <div class="card-body">
                  <div>
                    <label class="input-label mr-2">
                      {{ trans("admin.platformMessages.subject") }}:
                    </label>
                    <input v-model="translation.subject" required />
                    <validation-error
                      v-if="
                        formErrors?.translations?.length &&
                        formErrors?.translations[i - 1]?.subject?.length &&
                        !!formErrors?.translations[i - 1]?.subject.length
                      "
                      :errors="formErrors?.translations[i - 1].subject"
                    />
                  </div>
                  <div>
                    <label class="input-label mr-4">
                      {{ trans("admin.platformMessages.message") }}:
                    </label>
                    <textarea v-model="translation.message" required></textarea>
                    <validation-error
                      v-if="
                        formErrors?.translations?.length &&
                        formErrors?.translations[i - 1]?.message?.length &&
                        !!formErrors?.translations[i - 1].message[0]
                      "
                      :errors="formErrors?.translations[i - 1].message"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary mr-4" type="submit">
            {{ trans("general.formSaveLabel") }}
          </button>
          <button
            class="btn btn-primary mr-4"
            type="button"
            @click="cancelForm"
          >
            {{ trans("general.modalCancelButton") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderAdmin from "./Header.vue";
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import DerSelect from "../Utils/DerSelect.vue";
import FormError from "../Utils/FormError.vue";
import ValidationError from "../Utils/ValidationError.vue";
import { useWindowSize } from "@vueuse/core";

export default {
  components: {
    ValidationError,
    FormError,
    DerSelect,
    HeaderAdmin,
  },
  data() {
    return {
      messages: [],
      formData: [],
      formErrors: [],
      isLoading: false,
      width: 0,
      showForm: false,
      formTitle: "",
      form: {
        id: null,
        stage: "",
        interface: "",
        type: "",
        start_date: "",
        end_date: "",
        translations: [],
      },
    };
  },
  computed: {
    numberOfTranslations() {
      return this.form?.translations?.length ?? 1;
    },
    columns() {
      return this.width < 900 ? 1 : 2;
    },
  },
  mounted() {
    this.loadMessages();
    this.width = useWindowSize().width;
  },
  methods: {
    loadMessages() {
      this.isLoading = true;
      practitionerHttpService
        .get("/api/internal/admin/platform-messages")
        .then((response) => {
          this.messages = response.data?.messages;
          this.formData = response.data?.formData;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    createNewMessage() {
      this.clearForm();
      this.formTitle = this.trans("admin.platformMessages.createNewMessage");
      this.form = {
        id: null,
        stage: "",
        interface: "",
        type: "",
        start_date: "",
        end_date: "",
        translations: [],
      };
      this.showForm = true;
    },
    editMessage(message) {
      this.formTitle = this.trans("admin.platformMessages.editMessage");
      this.form = { ...message };
      this.showForm = true;
    },
    saveMessage() {
      this.formErrors.value = {};
      const url = this.form.id
        ? `/api/internal/admin/platform-messages/${this.form.id}`
        : "/api/internal/admin/platform-messages";
      const method = "post";
      practitionerHttpService[method](url, this.form)
        .then((response) => {
          this.loadMessages();
          this.showForm = false;
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            this.formErrors = error.response.data;
          } else {
            console.error(error);
          }
        });
    },
    deleteMessage(id) {
      practitionerHttpService
        .delete(`/api/internal/admin/platform-messages/${id}`)
        .then((response) => {
          this.loadMessages();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addTranslation() {
      this.form.translations.push({ locale: "", subject: "", message: "" });
    },
    cancelForm() {
      this.showForm = false;
      this.clearForm();
    },
    clearForm() {
      this.form.values = {};
      this.formErrors = [];
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
