<template>
  <div class="card">
    <div class="card-header">
      {{ label }}
    </div>
    <div class="card-body">
      <div class="card-text col-md-12">
        <DerToggleWithLabel
          v-model="features[featureId].active"
          disabled
          :label="
            !features[featureId].active
              ? trans('examination.ucr_sync_info')
              : label
          "
        />
      </div>
      <p />
      <div v-if="features[featureId].active" class="card-text col-md-12">
        <strong>{{ trans("examination.ucr_center_synced_id") }}:</strong>
        {{ center.ucr_id }}
      </div>
    </div>
  </div>
</template>

<script>
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    DerToggleWithLabel,
  },
  props: {
    center: {},
    features: {},
    featureId: {},
    label: String,
  },
};
</script>
