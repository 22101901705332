<template>
  <div>
    <div class="panel panel-default panel-fill mt-4">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("center.addNewCenter") }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="m-b-20 col-md-6" :class="{ 'has-error': errors.name }">
            <label for="FullName">{{ trans("center.name") }}</label>
            <i class="far fa-asterisk" />
            <input
              id="center_name"
              v-model="center.name"
              type="text"
              name="name"
              class="form-control"
              :placeholder="trans('center.name')"
            />
            <form-error v-if="errors.name" :errors="errors.name" />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.hsaId") }}</label>
            <input
              id="hsa-id"
              v-model="center.hsa_id"
              type="text"
              name="hsaid"
              class="form-control"
              :placeholder="trans('center.hsaId')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="contactPerson">{{
              trans("center.contactPerson")
            }}</label>
            <input
              id="contactPerson"
              v-model="center.contact_person"
              type="text"
              name="contactPerson"
              class="form-control"
              :placeholder="trans('center.contactPerson')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.contactNo") }}</label>
            <input
              id="contact_no"
              v-model="center.contact_no"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.contactNo')"
            />
          </div>

          <div class="m-b-20 col-md-6" :class="{ 'has-error': errors.email }">
            <label for="FullName">{{ trans("center.email") }}</label>
            <input
              id="email"
              v-model="center.email"
              type="email"
              name="name"
              class="form-control"
              :placeholder="trans('center.email')"
            />
            <form-error v-if="errors.email" :errors="errors.email" />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.fax") }}</label>
            <input
              id="fax"
              v-model="center.fax"
              type="text"
              name="fax"
              class="form-control"
              :placeholder="trans('center.fax')"
            />
          </div>

          <div class="row m-b-20 col-md-12">
            <div class="col">
              <hr />
            </div>
            <div class="col-auto">
              <span class="hr-text">{{ trans("patient.address") }}</span>
            </div>
            <div class="col">
              <hr />
            </div>
          </div>

          <div class="m-b-20 col-md-12">
            <label for="FullName">{{ trans("center.address") }}</label>
            <input
              id="address"
              v-model="center.address"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.address')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.city") }}</label>
            <input
              id="city"
              v-model="center.city"
              type="text"
              name="address"
              class="form-control"
              :placeholder="trans('center.city')"
            />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.postcode") }}</label>
            <input
              id="postcode"
              v-model="center.postcode"
              type="text"
              name="postcode"
              class="form-control"
              :placeholder="trans('center.postcode')"
            />
          </div>

          <div class="m-b-20 col-md-6" :class="{ 'has-error': errors.country }">
            <label for="FullName">{{ trans("center.country") }}</label>
            <i class="far fa-asterisk" />
            <country-select
              v-model="center.country"
              :country="center.country"
              :placeholder="trans('center.countryPlaceholder')"
              top-country="SE"
              class="form-control"
            />
            <form-error v-if="errors.country" :errors="errors.country" />
          </div>

          <div class="m-b-20 col-md-6">
            <label for="FullName">{{ trans("center.province") }}</label>
            <region-select
              v-model="center.province"
              :country="center.country"
              :placeholder="trans('center.provincePlaceholder')"
              :region="center.province"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="m-3">
        <span class="text-left"
          ><i class="far fa-asterisk" />
          {{ trans("center.requiredField") }}</span
        >
      </div>
    </div>

    <div class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("users.tabMenuLabelProducts") }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="row col-md-12">
          <div
            v-for="(product, key) in center.all_products"
            :key="key"
            class="col-md-2 col-sm-3"
          >
            <DerToggleWithLabel
              v-model="product.active"
              :label="product.display_name"
            />
          </div>
        </div>

        <clip-loader v-if="loading" />

        <div v-if="center.all_products.length === 0 && !loading">
          <h6 class="text-center">
            {{ trans("region.noProducts") }}
          </h6>
        </div>
      </div>
    </div>

    <platform-attributes-editor
      :platform="platform"
      :platform-attributes="center.platform_attributes"
      :platform-attribute-values="center.platform_attribute_values"
    />

    <div class="text-right mb-5">
      <button class="btn btn-grey" @click="cancelAddingCenter">
        {{ trans("general.modalCancelButton") }}
      </button>
      <button
        class="btn btn-primary"
        :disabled="!isValid || loading"
        @click="addCenterToRegion"
      >
        {{ trans("center.addNewCenter") }}
      </button>
    </div>
  </div>
</template>

<script>
import PlatformAttributesEditor from "../PlatformAttributes/PlatformAttributesEditor.vue";
import "vue3-country-region-select";
import DerToggleWithLabel from "../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    PlatformAttributesEditor,
    DerToggleWithLabel,
  },
  data: function () {
    return {
      loading: false,
      platform: undefined,
      center: {
        id: null,
        name: "",
        hsa_id: "",
        contact_person: "",
        email: "",
        contact_no: "",
        fax: "",
        address: "",
        city: "",
        postcode: "",
        country: "",
        province: "",
        platform_attributes: {},
        platform_attribute_values: {},
        // cannot be changed by the region admin, but center will be added with the defaults
        all_features: {},
        all_products: {},
      },
      errors: [],
    };
  },
  computed: {
    isValid: function () {
      if (!this.center.name || !this.center.country) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted: function () {
    this.getRegionFeatures();
  },
  methods: {
    getRegionFeatures: function () {
      this.loading = true;

      Promise.all([axios.get("/api/internal/region-admin/center/new")])
        .then(([newCenterResponse]) => {
          const data = newCenterResponse.data;
          this.platform = data.platform;
          this.center.platform_attributes = data.platform_attributes;
          this.center.platform_attribute_values =
            data.platform_attribute_values;
          this.center.all_features = data.all_features;
          this.center.all_products = data.all_products;
        })
        .catch((error) => {
          console.error("Get region features error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addCenterToRegion: function () {
      this.errors = [];
      this.loading = true;

      axios
        .post("/api/internal/region-admin/center/new", this.center)
        .then((response) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.loading = false;
          this.$router.push({ path: `/region/centers` });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    cancelAddingCenter: function () {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.hr-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 2em;
  font-weight: bold;
}
</style>
