<template>
  <div>
    <header-admin :active-section="1" />

    <div
      class="col-lg-12 pt-2 pb-3 my-3"
      :class="!center.active && !loading ? 'gray-background' : ''"
    >
      <div v-if="!center.active && !loading" class="mt-2">
        <h4 class="float-left">This centre is inactive</h4>
      </div>
      <div v-if="!loading" class="text-right my-2">
        <button class="btn btn-secondary" @click="$router.go(-1)">
          {{ trans("general.back") }}
        </button>
        <button
          class="btn btn-primary ml-2"
          :disabled="
            !center.name || !center.country || loading || !center.active
          "
          :title="!center.active ? 'Centre is inactive' : ''"
          @click="saveData()"
        >
          {{
            center.id
              ? trans("general.formSaveLabel")
              : trans("center.addNewCenter")
          }}
        </button>
      </div>

      <clip-loader v-show="loading" />

      <admin-center-contact-info
        v-if="!loading"
        :center="center"
        :errors="errors"
      />

      <admin-center-region v-if="!loading" :center="center" />

      <patient-lookup-type-selector v-if="!loading" :center="center" />

      <admin-products v-if="!loading" :products="products" :center="center" />

      <admin-features
        v-if="!loading"
        :features="center.all_features"
        :center="center"
      />

      <platform-attribute-editor
        v-if="!loading"
        :platform="center.platform"
        :platform-attributes="center.platform_attributes"
        :platform-attribute-values="center.platform_attribute_values"
      />

      <user-table
        v-if="!loading && center.id"
        :title="trans('center.primaryUsers')"
        :users="center.primaryUsers"
        :show-admin-roles="true"
        @user-click="openUser"
      />

      <user-table
        v-if="!loading && center.id"
        :title="trans('center.groupTableConnectedUsers')"
        :users="center.connectedUsers"
        :show-admin-roles="false"
        @user-click="openUser"
      />

      <center-activation-panel
        v-if="!loading && center.id"
        :center="center"
        @refresh="loadData"
      />

      <div v-if="!loading" class="panel panel-default panel-fill">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("center.examinationCenterSettings") }}
          </h3>
        </div>
        <div class="panel-body col-md-12">
          <div class="col-md-12 col-sm-3">
            <DerToggleWithLabel
              v-model="centerTypeConsultation"
              :label="trans('center.examinationCenter')"
            />
          </div>

          <div
            v-if="
              (center.connected_centers && center.connected_centers.length) >
                0 || center.type === 'consultation'
            "
            class="card my-3"
          >
            <div class="card-header">
              {{ trans("users.connectedCentersLabel") }}
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ trans("center.name") }}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        connectedCenter, index
                      ) in center.connected_centers"
                      :key="index"
                    >
                      <td class="centerCell">
                        {{ connectedCenter.name }}
                      </td>

                      <td>
                        <div class="text-right">
                          <button
                            class="btn btn-danger"
                            @click="removeConnectedCenter(connectedCenter.id)"
                          >
                            {{ trans("general.formDeleteLabel") }}
                          </button>
                        </div>
                      </td>
                    </tr>

                    <!-- form to add new connected center -->
                    <tr
                      v-if="
                        isAddingNewConnectedCenter &&
                        center.type === 'consultation'
                      "
                      class="editing-centers"
                    >
                      <td :class="{ 'has-error': errors.message }">
                        <select2 v-model="selectedCenterId" name="centers">
                          <option value="" />
                          <option
                            v-for="(center, index) in regularCenters"
                            :key="index"
                            :value="center.id"
                          >
                            {{ center.name }}
                          </option>
                        </select2>
                        <form-error
                          v-if="errors.message"
                          :errors="errors.message"
                        />
                      </td>

                      <td>
                        <div
                          v-if="isAddingNewConnectedCenter"
                          class="text-right"
                        >
                          <button
                            class="btn btn-grey m-1"
                            @click="cancelAddingNewSelectedCenter"
                          >
                            {{ trans("general.modalCancelButton") }}
                          </button>
                          <button
                            class="btn btn-primary saveSelectedCentre"
                            @click="addCenterToExaminationCenter"
                          >
                            {{ trans("center.connectToExaminationCenter") }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button
                  v-if="
                    !isAddingNewConnectedCenter &&
                    center.type === 'consultation'
                  "
                  class="btn btn-primary m-1 float-right addCenterButton"
                  @click="addingCenterToExaminationCenter"
                >
                  {{ trans("admin.addNewCenter") }}
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="
              center.consultation_centers &&
              center.consultation_centers.length > 0
            "
            class="card my-3"
          >
            <div class="card-header">
              {{ trans("center.connectedExaminationCentersLabel") }}
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ trans("center.name") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        connectedConsultationCenter, index
                      ) in center.consultation_centers"
                      :key="index"
                    >
                      <td>{{ connectedConsultationCenter.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlatformAttributeEditor from "../../PlatformAttributes/PlatformAttributesEditor.vue";
import AdminCenterContactInfo from "./AdminCenterContactInfo.vue";
import AdminProducts from "../Products/AdminProducts.vue";
import AdminFeatures from "../Features/AdminFeatures.vue";
import HeaderAdmin from "./../Header.vue";
import AdminCenterRegion from "./AdminCenterRegion.vue";
import PatientLookupTypeSelector from "./PatientLookupTypeSelector.vue";
import CenterActivationPanel from "./CenterActivationPanel.vue";
import UserTable from "./UserTable.vue";
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    CenterActivationPanel,
    DerToggleWithLabel,
    PlatformAttributeEditor,
    AdminCenterContactInfo,
    AdminCenterRegion,
    PatientLookupTypeSelector,
    AdminProducts,
    AdminFeatures,
    HeaderAdmin,
    UserTable,
  },
  data() {
    return {
      loading: true,
      centerTypeConsultation: false,
      selectedCenterId: null,
      isAddingNewConnectedCenter: false,
      regularCenters: [],
      center: {
        id: null,
        active: null,
        postcode: null,
        all_features: {},
        all_products: {},
        country: null,
        province: null,
        platform_attributes: {},
        platform_attribute_values: {},
        region_id: null,
        connected_centers: [],
        consultation_centers: [],
        ucr_id: null,
        patient_lookup_type: null,
      },
      platform: "",
      products: {},
      users: {
        primaryCenters: {},
        resource: {},
      },
      errors: [],
      regions: [],
      regionsLoading: true,
    };
  },
  created: function () {
    this.loadData();
  },
  methods: {
    cancelAddingNewSelectedCenter: function () {
      this.isAddingNewConnectedCenter = false;
      this.selectedCenterId = null;
      this.errors = [];
    },
    addingCenterToExaminationCenter: function () {
      this.isAddingNewConnectedCenter = true;
    },
    addCenterToExaminationCenter: function () {
      this.errors = [];

      axios
        .post(
          "/api/internal/admin/centers/couple-to-consultation-center/" +
            this.selectedCenterId +
            "/" +
            this.center.id,
        )
        .then((response) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.cancelAddingNewSelectedCenter();
          this.loadData();
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    removeConnectedCenter: function (centerId) {
      this.$vueAlert
        .confirm(
          this.trans(
            "center.removeConnectedCenterFromExaminationCenterConfirmation",
          ),
          undefined,
          undefined,
          {
            confirmButtonText: this.trans("general.modalConfirmButton"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          },
        )
        .then(() => {
          axios
            .delete(
              "/api/internal/admin/centers/decouple-from-consultation-center/" +
                centerId +
                "/" +
                this.center.id,
            )
            .then((response) => {
              this.loadData();
            })
            .catch((error) => {
              this.userErrorMessage(error);
            });
        })
        .catch(() => {});
    },
    loadData: async function () {
      this.loading = true;
      try {
        const centerResponse = await axios.get(
          "/api/internal/admin/centers/" + this.$route.params.id,
        );
        let data = centerResponse.data;
        this.center = data.center;
        this.centerTypeConsultation = this.center.type === "consultation";
        this.loading = false;
        this.getRegularCenters();
        const productsResponse = await axios.get("/api/internal/products");
        this.setProductData(productsResponse.data);
      } catch (error) {
        this.loading = false;
        this.userErrorMessage(error);
      }
    },
    saveData: function () {
      let saveUri =
        "/api/internal/admin/centers" +
        (this.$route.params.id !== "new" ? "/" + this.$route.params.id : "");

      this.errors = [];

      let postData = this.center;

      //CREATE A NEW OBJECT WITH DESIRED STRUCTURE
      let productPostData = {
        products: {},
      };
      Object.values(this.products).forEach((product) => {
        // Check if the product already has a config object
        if (!productPostData.products[product.id]) {
          productPostData.products[product.id] = {
            id: product.id,
            active: product.active,
            config: {
              defaults: {},
            },
          };
        }
        product.config.defaults.forEach((field) => {
          productPostData.products[product.id].config.defaults[
            field.fieldName
          ] = field.selectedOption?.value ?? null;
        });
      });
      postData.all_products = {
        ...productPostData.products,
      };

      this.centerTypeConsultation === true
        ? (postData.type = "consultation")
        : (postData.type = "regular");

      axios
        .post(saveUri, postData)
        .then((response) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });

          if (response.data.isNew) {
            this.$router.push({ name: "Admin" });
            // Clean form
            this.center = {
              id: null,
              postcode: null,
              country: null,
              province: null,
              platform_attributes: {},
              platform_attribute_values: {},
            };
          }
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    openUser: function (id) {
      this.$router.push({ name: "AdminUsersEdit", params: { id: id } });
    },
    getRegularCenters: function () {
      axios
        .get("/api/internal/admin/all-centers-of-type/regular")
        .then((data) => {
          let collection = data.data;

          if (this.center.connected_centers?.length) {
            this.regularCenters = this.filterListFromAlreadySelectedItems(
              collection,
              this.center.connected_centers,
            );
          } else {
            this.regularCenters = data.data;
          }
        })
        .catch((error) => {
          console.log("Get regular centers error: ", error);
        });
    },
    // to do: maybe make this a reusable funtion in utils
    filterListFromAlreadySelectedItems: function (listToFilter, listOfItems) {
      const listOfItemId = listOfItems.map((item) => {
        return item.id;
      });
      const filteredList = listToFilter.filter(
        (element) => !listOfItemId.includes(element.id),
      );

      return filteredList;
    },
    userAdminRoles: function (user) {
      let userAdminRoleList = user.roles.map((role) => {
        if (
          role.name === "center_admin" ||
          role.name === "region_admin" ||
          role.name === "case_admin"
        ) {
          return role.name;
        }
      });

      return userAdminRoleList;
    },
    setProductData: function (products) {
      products.forEach((product) => {
        this.products[product.id] = product;
        let centerProduct = Object.values(this.center.all_products).find(
          (p) => p.id === product.id,
        );
        this.products[product.id].active = !!centerProduct?.active;
        this.products[product.id].config.defaults.forEach((field) => {
          field.options.forEach((option) => {
            option.selected =
              centerProduct.config.defaults[field.fieldName] === option.value;
            if (option.selected) {
              field.selectedOption = option;
            }
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gray-background {
  background-color: rgba(214, 214, 214, 0.603);
}
</style>
