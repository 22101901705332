<template>
  <div class="card">
    <div class="card-header">{{ label }}</div>
    <div class="card-body">
      <div class="card-text col-md-12 tw-mb-2">
        <DerToggleWithLabel
          v-model="features[featureId].active"
          :label="label"
          :disabled="!center.active"
        />
      </div>
      <div class="card-text">
        <div v-if="features[featureId].active">
          <div
            v-for="key in Object.keys(features[featureId].config)"
            :key="key"
            class="col-md-12"
          >
            <label :for="key">
              {{
                key.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase())
              }}
            </label>
            <input
              v-model="features[featureId].config[key]"
              type="text"
              class="form-control"
              :disabled="!features[featureId].active"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggleWithLabel from "../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    DerToggleWithLabel,
  },
  props: {
    center: {},
    features: {},
    featureId: {},
    label: String,
  },
};
</script>
