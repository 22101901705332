<template>
  <div>
    <div v-if="loading" class="overlay">
      <clip-loader />
    </div>
    <div class="user-settings-header">
      <div class="avatar-wrapper">
        <div class="circle">
          <img
            v-if="avatar.image"
            :src="avatar.image"
            alt="Avatar"
            class="avatar-img"
          />
          <span v-else class="avatar-initials">{{ user.initials }}</span>
        </div>
        <div class="user-info">
          <avatar-cropper
            v-model="showCropper"
            upload-url="/api/internal/auth/upload-avatar"
            :request-options="{
              method: 'POST',
              headers: avatar.headerSettings,
            }"
            :labels="avatar.buttonLabels"
            @uploaded="handleUploaded"
          />
          <h4>{{ user.fullName }}</h4>
          <p class="text-muted">
            {{ user.title }}
          </p>
        </div>
        <div class="edit-btn">
          <div class="dropdown">
            <button
              class="form-image-upload__edit btn btn-grey"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              type="button"
            >
              <i class="fas fa-pen editIcon" />
              <span>{{ trans("users.edit") }}</span>
            </button>
            <div class="dropdown-menu">
              <button
                class="dropdown-item"
                type="button"
                @click="handleEditClick"
              >
                {{ trans("users.uploadLogo") }}
              </button>
              <button
                v-if="avatar.image"
                class="dropdown-item"
                type="button"
                @click="handleClearClick"
              >
                {{ trans("users.removeLogo") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab !== 'twofa'" class="text-right user-settings-button">
        <Button
          :disabled="!isValid || loading"
          :title="isValid ? '' : trans('examination.requiredFieldMissing')"
          type="button"
          :is-loading="isSaving"
          @click="saveFormData"
        >
          {{ trans("users.saveButton") }}
        </Button>
      </div>
    </div>

    <div class="m-t-10" :class="{ 'm-t-custom': activeTab === 'twofa' }">
      <user-header
        v-model:active-tab="activeTab"
        component-purpose="userSettings"
      />

      <div v-if="!loading" class="tab-content">
        <div
          id="profile"
          :class="{ 'tab-pane': true, active: activeTab === 'profile' }"
        >
          <user-profile own-profile="true" :user="user" :errors="errors" />
          <div class="text-right mb-5">
            <button
              class="btn btn-primary"
              :disabled="!isValid || loading"
              :title="isValid ? '' : trans('examination.requiredFieldMissing')"
              @click="saveFormData"
            >
              {{ trans("users.saveButton") }}
            </button>
          </div>
        </div>

        <div
          id="settings"
          :class="{ 'tab-pane': true, active: activeTab === 'settings' }"
        >
          <div class="row">
            <div class="col-md-12">
              <user-settings
                :user="user"
                :centers="centers"
                :primary-center="primaryCenter"
                :external-resources="externalResources"
                :errors="errors"
                :user-roles="userRoles"
                :dropdown-role="dropdownRole(user)"
              />
            </div>
          </div>
        </div>

        <div
          id="products"
          :class="{ 'tab-pane': true, active: activeTab === 'products' }"
        >
          <user-products
            ref="userProducts"
            user-id=""
            source="settings"
            context=""
          />
        </div>

        <div
          id="twofa"
          :class="{ 'tab-pane': true, active: activeTab === 'twofa' }"
        >
          <two-factor-authentication
            v-if="activeTab === 'twofa'"
            :user="user"
            @verification-success="fetchUserOnVerificationSuccess"
          />
        </div>

        <div
          id="history"
          :class="{ 'tab-pane': true, active: activeTab === 'history' }"
        >
          <user-history
            :timeline="timeline"
            @get-more-notifications="getMoreNotifications"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";

import TwoFactorAuthentication from "./TwoFactorAuthentication.vue";
import UserHeader from "./UserHeader.vue";
import UserHistory from "./UserHistory.vue";
import UserProfile from "../Admin/Users/User/UserProfile.vue";
import UserProducts from "../Admin/Users/User/UserProducts.vue";
import UserSettings from "./UserSettings.vue";
import DropdownRole from "../../mixins/DropdownRole.vue";
import { lang } from "../../i18n";
import { practitionerAuthToken } from "../../app/practitioner-portal/auth/practitioner-auth-token";

import Button from "../Layout/Button.vue";

export default {
  components: {
    AvatarCropper,
    TwoFactorAuthentication,
    UserHeader,
    UserHistory,
    UserProfile,
    UserProducts,
    UserSettings,
    Button,
  },
  mixins: [DropdownRole],
  data() {
    return {
      activeTab: "profile",
      errors: [],
      loading: false,
      isSaving: false,
      user: "",
      primaryCenterId: null,
      primaryCenter: {
        id: null,
      },
      centers: "",
      externalResources: "",
      csrfToken: Laravel.csrfToken,
      products: {
        notifications_new_examinations: false,
        consensus_notifications: false,
        notification_new_document_consult_answer: false,
        unanswered_cases: false,
        cases_without_photos: false,
      },
      timeline: {
        data: {},
        paginate: {},
      },
      showCropper: false,
      avatar: {
        image: undefined,
        headerSettings: {},
        buttonLabels: { submit: "Submit", cancel: "Cancel" },
        cropperOptions: {
          autoCropArea: 1,
          viewMode: 1,
          movable: 1,
          zoomable: 1,
        },
      },
      roles: [],
      hasAnyExternalResourceRole: false,
      userRoles: {
        consultant: false,
        senior_consultant: false,
        user: false,
        center_admin: false,
        region_admin: false,
        external_resource: false,
        region_external_resource: false,
        admin: false,
        case_admin: false,
      },
    };
  },
  computed: {
    isValid: function () {
      if (
        !this.user.culture ||
        !this.user.username ||
        !this.user.email ||
        !this.user.firstname ||
        !this.user.lastname
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created: function () {
    this.loadData();
  },
  mounted: function () {
    this.avatar.headerSettings = {
      Authorization: "Bearer " + practitionerAuthToken.get(),
    };
  },
  methods: {
    loadData: function () {
      this.loading = true;
      axios
        .get("/api/internal/auth/settings")
        .then((data) => {
          let collection = data.data;
          this.user = collection.user;
          this.primaryCenter = collection.primaryCenter;

          this.centers = collection.centers;
          this.externalResources = collection.externalResources;
          this.products = collection.user.settings_products;
          this.timeline = collection.notifications;

          this.avatar.image = this.$store.state.user.avatar;

          // assign role to true if the role is in the assigned user roles list
          let assignedUserRoles = this.user.roles;
          assignedUserRoles.map((value) => {
            this.userRoles[value.name] = true;
          });

          this.loading = false;
        })
        .catch((error) => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    fetchUserOnVerificationSuccess() {
      axios
        .get("/api/internal/auth/settings")
        .then((data) => {
          let collection = data.data;
          this.user = collection.user;
        })
        .catch((error) => {
          this.userErrorMessage(error);
        });
    },
    saveFormData: function (e) {
      e.preventDefault();

      this.errors = [];

      this.user.products = this.products;
      this.user.userRoles = this.userRoles;
      this.user.settings_products = this.$refs.userProducts.getProducts();

      this.isSaving = true;

      axios
        .post("/api/internal/auth/settings", this.user)
        .then((response) => {
          if (response.data.success) {
            this.isSaving = false;
            this.$notify({
              text: lang.get("general.notificationSaved"),
              type: "success",
              duration: 1000,
            });

            // With this form, it's possible to change the preferred locale.
            lang.setLocale(this.user.culture);
          }
        })
        .catch((error) => {
          this.isSaving = false;
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    handleEditClick() {
      this.showCropper = true;
    },
    handleClearClick() {
      axios
        .delete("/api/internal/auth/delete-avatar")
        .then((response) => {
          this.avatar.image = response.avatar;
          this.$store.commit("setUserAvatar", this.avatar.image);

          this.$notify({
            text: lang.get("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    handleUploaded({ response }) {
      response.json().then((data) => {
        this.avatar.image = data.avatar;
        this.$store.commit("setUserAvatar", this.avatar.image);

        this.$notify({
          text: lang.get("general.notificationSaved"),
          type: "success",
          duration: 1000,
        });
      });
    },
    getMoreNotifications: function () {
      if (this.timeline.paginate.next_page_url) {
        axios
          .get(this.timeline.paginate.next_page_url)
          .then((response) => {
            let collection = response.data;

            if (collection) {
              for (var i = 0; i < collection.data.length; i++) {
                this.timeline.data.push(collection.data[i]);
              }

              this.timeline.paginate = collection.paginate;
            }
          })
          .catch((error) => {
            this.userErrorMessage(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/components/custom_user_avatar.scss";

.form-image-upload__edit {
  position: absolute;
  bottom: -1em;
  left: 50%;
  transform: translateX(-50%);
}
$custom-margin-top: 10px;

.m-t-custom {
  margin-top: $custom-margin-top !important;
}
</style>
